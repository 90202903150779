import React, {useContext} from "react";
import styled from "styled-components";
// Icons
import {SvgIcon} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// Context
import SearchContext from "../context/filterContext";
import {transitionFast} from "./styles/Mixins";

// Styles
export const SearchFieldContainer = styled.div `
  position: relative;
`
export const SearchFieldInput = styled.input `
  height: 48px;
  padding-right: 48px;
  font-size: 15px;
  border: none;
  color: ${({theme}) => theme.primaryColor};
  outline: none;
  width: 48px;
  transition: all ease 0.5s;
  border-radius: 50px;
  cursor: pointer;
  background-color: transparent;

  &:focus, &:hover, &:not(:invalid) {
    width: 200px;
    cursor: text;
    padding-left: 20px;
    border: solid thin ${({theme}) => theme.primaryColor} ;
  }
  &:focus + .icon, &:not(:invalid) + .icon {
    pointer-events: all;
    cursor: pointer;
    color: ${({theme}) => theme.blue};
  }
  &:hover + .icon {
    color: ${({theme}) => theme.blue};
  }
`
export const SearchFieldIcon = styled.div `
  position: absolute;
  width: 48px;
  height: 48px;
  top: 0;
  right: 0;
  padding: 8px;
  pointer-events: none;
  ${transitionFast};
  
  svg {
    width: 100%;
    height: 100%;
  }
`

function SearchField():JSX.Element {
  const {searchString, setSearchString} = useContext(SearchContext)

  return (
    <SearchFieldContainer className="container">
      <SearchFieldInput
        required
        placeholder="Search by name..."
        className="input"
        name="text"
        type="text"
        value={searchString}
        onChange={(event => {setSearchString(event.target.value)})}
      />
      <SearchFieldIcon className="icon">
        <SvgIcon component={SearchIcon}/>
      </SearchFieldIcon>
    </SearchFieldContainer>
  )
}

export default SearchField