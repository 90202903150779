import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { ArrowUpward } from "@material-ui/icons";
import {SvgIcon} from "@mui/material";
import {transitionFast} from "./styles/Mixins";


type StyledScrollButtonProps = {
  showButton: boolean;
}

export const StyledScrollButton = styled.button<StyledScrollButtonProps> `
  position: fixed;
  bottom: 2rem;
  left: 3%;
  right: auto;
  z-index: 1000;
  color: ${({theme}) => theme.secondaryColor};
  background-color: transparent;
  border: none;
  padding: 1rem;
  cursor: pointer;
  display: ${({showButton}) => showButton ? "block" : "none"};

  @media (max-width: 800px) {
    display: none !important;
  }

  svg {
    ${transitionFast}
    &:hover {
      color: ${({theme}) => theme.blue};
      cursor: pointer;
    }
  }
`

export const ScrollButton: React.FC = () => {
  const [showButton, setShowButton] = useState<boolean>(false);

  useEffect(() => {
    const handleVisibility = () => {
      window.pageYOffset > 100 ? setShowButton(true) : setShowButton(false);
    }

    window.addEventListener("scroll", handleVisibility);

    return () => {
      window.removeEventListener("scroll", handleVisibility);
    }
  }, []);

  return (
    <StyledScrollButton
      showButton={showButton}
      onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}
    >
      <SvgIcon component={ArrowUpward} fontSize={"large"}/>
    </StyledScrollButton>
  )
}