import React, {useEffect, useState} from "react";
import {Container} from "../../../components/styles/Container.styled";
import {useNotification} from "../../../hooks/useNotification";
import {useNavigate, useParams} from "react-router-dom";
import {SongsApi} from "../../../lib/apiHelpers";
import Loader from "../../../components/Loader";
import {SongWrapper} from "../../song/Song";
import {InputWrapper, StyledInputTextarea, StyledSubmit, TextareaWrapper} from "../Login";

export const EditNote: React.FC = () => {
  const {addNotification} = useNotification()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const { id } = useParams()

  const [note, setNote] = useState<string>("")

  const loadNote = async () => {
    try {
      setLoading(true)
      const response = await SongsApi.getNoteForSong(id?.toString() || "")
      setNote(response.data.note)
    } catch (error: any) {
      addNotification("error", "Chyba pri načítaní poznámky: " + error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  const submitNote = async (e: any) => {
    e.preventDefault()
    console.log(id)
    try {
      const response = await SongsApi.createNoteForSong(id?.toString() || "", {note: note})
      addNotification("success", "Poznámka bola úspešne pridaná.")
      navigate(`/song/${id}`)
    } catch (error: any) {
      addNotification("error", "Chyba pri upravovaní poznámky: " + error.response.data.message)
    } finally {
    }
  }

  useEffect(() => {
    loadNote()
  }, []);

  return (
    <Container>
      <Loader loading={loading}>
        <SongWrapper>
          <form onSubmit={submitNote}>
            <TextareaWrapper>
              <StyledInputTextarea
                placeholder="Napíšte poznámku"
                required
                value={note}
                onChange={(e) => setNote(e.target.value)}
              ></StyledInputTextarea>
            </TextareaWrapper>
            <br/>
            <StyledSubmit type="submit">
              <span className={"button-content"}>Pridaj poznámku</span>
            </StyledSubmit>
          </form>
        </SongWrapper>
      </Loader>
    </Container>
  )
}