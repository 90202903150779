import React, {useEffect} from "react";
import {useNotification} from "../../hooks/useNotification";
import styled from "styled-components";

interface StyledNotificationProps {
  type?: string;
}

export const StyledNotification = styled.div<StyledNotificationProps> `
  margin: 0.5rem;
  padding: 0.7rem;
  border-radius: 50px;
  cursor: pointer;
  border: 1px solid;
  color: ${({ type }) => {
    switch (type) {
      case 'success':
        return ({theme}) => theme.blue;
      case 'error':
        return ({theme}) => theme.red;
      case 'information':
        return ({theme}) => theme.blue;
      default:
        return ({theme}) => theme.primaryColor;
    }
  }};
  border-color: ${({ type }) => {
    switch (type) {
      case 'success':
        return ({theme}) => theme.blue;
      case 'error':
        return ({theme}) => theme.red;
      case 'information':
        return ({theme}) => theme.blue;
      default:
        return ({theme}) => theme.primaryColor;
    }
  }};
  background-color: ${({theme}) => theme.backgroundColor};
  width: max-content;
  display: flex;
`


export interface NotificationProps {
  type?: string;
  message?: string;
  timeout?: number;
  identifier: string;
}

export const Notification: React.FC<NotificationProps> = ({type, message, timeout, identifier}) => {
  const { removeNotification } = useNotification();

  useEffect(() => {
    setTimeout(() => {
      removeNotification(identifier)
    }, timeout)
  }, [])

  return (
    <StyledNotification type={type}>
      {message}
    </StyledNotification>
  )
}