export const responseCode = {
    OK: 200,
    Unauthorized: 401
}

export const errorMessages = {
    unauthorized: "Neznámy užívateľ",
    unknown: "Nastala neznáma chyba",
    download: "Vyskytla sa chyba pri načítavaní dát",
    upload: "Vyskytla sa chyba pri nahrávaní dát",
}

export const navigationUrlParts = {
    song: "/song",
    playlistSong: "/playlistSong",
    playlist: "/playlist"
}

export const playlistPropertyTranslations = {
    name: "Názov",
    description: "Popis",
    dirigent: "Dirigent",
    place: "Miesto",
    datetime: "Dátum a čas"
}

export const songCategories = [
    "Úvod",
    "Prijímanie",
    "Pôstna",
    "Cez rok",
    "Advent",
    "Pôst",
    "Vianoce",
    "Veľká noc",
    "Introitus",
    "Offertorium",
    "Communio",
    "Gratiarum",
    "Adorácia",
    "Záver",
    "Mariánske",
    "K Duchu svätému",
    "K Svätým",
    "Svadobné",
    "Kyrie",
    "Gloria",
    "Credo",
    "Sanctus",
    "Modlitba Pána",
    "Agnus Dei",
    "Alelujový verš",
    "Verš pred Evanjeliom",
    "Sekvencie"
]

const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
};

export const devices = {
    mobileS: `(max-width: ${sizes.mobileS})`,
    mobileM: `(max-width: ${sizes.mobileM})`,
    mobileL: `(max-width: ${sizes.mobileL})`,
    tablet: `(max-width: ${sizes.tablet})`,
    laptop: `(max-width: ${sizes.laptop})`,
    laptopL: `(max-width: ${sizes.laptopL})`,
    desktop: `(max-width: ${sizes.desktop})`,
};