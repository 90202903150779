import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {transitionFast} from "../styles/Mixins";

// Styles
export const StyledLogo = styled.div `
  width: 20%;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  a {
    height: 100%;
    display: block;
  }
  svg {
    width: auto;
    display: block;
    height: 100%;
    ${transitionFast};
    &:hover path {
      fill: ${({theme})=>theme.blue};
    }
    path {
      ${transitionFast};
    }
  }
`

function Logo(): JSX.Element {
  return (
    <StyledLogo>
      <Link to={"/"}>
        <svg width="69" height="24" viewBox="0 0 69 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M37.9674 10.1621H22.3168V13.8284H37.9674V10.1621Z" fill="#7A7A7A"/>
          <path d="M37.9674 17.3337H22.3168V21H37.9674V17.3337Z" fill="#7A7A7A"/>
          <path d="M37.9674 3H22.3168V6.66632H37.9674V3Z" fill="#7A7A7A"/>
          <path d="M64.2663 3H60.6V15.9221H64.2663V3Z" fill="#7A7A7A"/>
          <path d="M64.2663 17.3337H60.6V21H64.2663V17.3337Z" fill="#7A7A7A"/>
          <path d="M57.1042 8.05895V6.48633C57.1116 5.86824 56.9441 5.26066 56.621 4.73369C56.3109 4.21333 55.8742 3.77988 55.3516 3.47369C54.8314 3.15998 54.2348 2.99605 53.6274 3.00001H44.94C44.327 2.99883 43.7249 3.16243 43.1968 3.47369C42.6721 3.77719 42.2348 4.21125 41.9274 4.73369C41.6142 5.26401 41.4535 5.87051 41.4631 6.48633V10.3516C41.4535 10.9674 41.6142 11.5739 41.9274 12.1042C42.2348 12.6267 42.6721 13.0607 43.1968 13.3642C43.7249 13.6755 44.327 13.8391 44.94 13.8379H53.4095V17.3526H45.1105V15.9411H41.4631V17.5137C41.4563 18.1262 41.6168 18.7289 41.9274 19.2568C42.2345 19.782 42.6717 20.2192 43.1968 20.5263C43.7249 20.8376 44.327 21.0012 44.94 21H53.6274C54.2348 21.004 54.8314 20.84 55.3516 20.5263C55.8758 20.2139 56.3125 19.7739 56.621 19.2474C56.9419 18.7229 57.1093 18.119 57.1042 17.5042V13.6484C57.1116 13.0303 56.9441 12.4228 56.621 11.8958C56.3109 11.3754 55.8742 10.942 55.3516 10.6358C54.8314 10.3221 54.2348 10.1582 53.6274 10.1621H45.101V6.66632H53.4V8.05895H57.1042Z" fill="#7A7A7A"/>
          <path d="M3.00947 3V8.02105H6.68526V6.66631H14.9653V17.3526H6.64736V13.8379H3.00947V17.5137C3.00545 18.1271 3.16925 18.7299 3.48316 19.2568C3.79031 19.782 4.2275 20.2192 4.75263 20.5263C5.28017 20.8389 5.8826 21.0026 6.49579 21H15.1737C15.7839 21.0028 16.3833 20.839 16.9074 20.5263C17.4259 20.2098 17.8586 19.7706 18.1674 19.2474C18.4883 18.7229 18.6557 18.119 18.6505 17.5042V3H3.00947Z" fill="#7A7A7A"/>
        </svg>
      </Link>
    </StyledLogo>
  )
}

export default Logo