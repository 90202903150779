import React, {useState, useEffect, useContext} from "react";
import {NavLink, Link} from "react-router-dom";
import css from "*.scss";
import axios, {AxiosResponse} from "axios";
// Global context
import FilterContext from "../../context/filterContext";
// Interfaces
import {Tag} from "../../lib/models";
// Styles
import styled from "styled-components"
import {StyledCheckboxLabel, StyledCheckboxSpan, StyledCheckbox} from "../../components/styles/Checkbox.styled";
import {transitionFast} from "../../components/styles/Mixins";
import {devices} from "../../lib/constants";
import {useNotification} from "../../hooks/useNotification";
import {TagsApi} from "../../lib/apiHelpers";
import Loader from "../../components/Loader";


export const FiltersWrapper = styled.div<{ display: boolean, height: number }> `
  ${transitionFast};
  margin-top: 0;
  width: 100%;
  color: ${({theme}) => theme.secondaryColor};
  background-color: ${({theme}) => theme.backgroundColor};
  overflow: hidden;
  height: ${props => props.display ? props.height + 1 + "px": "0"};
  
  h2 {
    width: 100%;
    text-align: center;
    margin-top: 0;
    padding-top: 0;
  }
`
export const StyledFilters = styled.div `
  width: 94%;
  margin-left: 3%;
  border-bottom: 1px solid ${({theme}) => theme.tertiaryColor};
  display: flex;
  flex-wrap: wrap;

  @media ${devices.laptop} {
    overflow: scroll;
    max-height: 60vh;
  }
  @media ${devices.tablet} {
    width: 100%;
  }
  
`
export const FiltersColumn = styled.div `
  width: 20%;
  padding: 1%;
  
  @media ${devices.laptop} {
    width: 50%;
  }
  @media ${devices.tablet} {
    width: 100%;
  }
`



function Filters(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const {addNotification} = useNotification()

  const [allTags, setAllTags] = useState<Tag[]>([])
  const {searchTags, setSearchTags} = useContext(FilterContext)
  const {displayFilter, setDisplayFilter} = useContext(FilterContext)

  function getHeight() {
    return document.getElementById("filtersWrapper")?.scrollHeight || 0
  }

  const fetchTags = async () => {
    try {
      setLoading(true)
      const response = await TagsApi.getTags()
      setAllTags(response.data)
    } catch (error: any) {
      addNotification("error", "Chyba pri načítaní filtrov: " + error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTags()
    getHeight()

    return () => {
      setDisplayFilter(false); // Set the filter display context to false on unmount
    };
  }, [])

  const mapFilters = (tags: Tag[]) => {
    return (
      tags.map(({id, name}) => (
        <StyledCheckboxLabel key={id}>
          <StyledCheckbox
            type={"checkbox"}
            id={"tag" + id}
            className={"sidepanel__checkbox"}
            checked={(searchTags.some(((i:number) => i ==id)))}
            onChange={event => {
              if (event.target.checked) {
                setSearchTags((searchTags:[]) => [...searchTags, id])
              } else {
                setSearchTags(searchTags.filter((item:number) => item !== id))
              }
            }}
          />
          <StyledCheckboxSpan/>
          {name}
        </StyledCheckboxLabel>
      ))
    )
  }

  return (
    <FiltersWrapper display={displayFilter} id={"filtersWrapper"} height={getHeight()}>
      <Loader loading={loading}>
        <StyledFilters>
          <FiltersColumn>
            {mapFilters(allTags.filter(({category}) => category === "Obdobia"))}
          </FiltersColumn>
          <FiltersColumn>
            {mapFilters(allTags.filter(({category}) => category === "Časti omše"))}
          </FiltersColumn>
          <FiltersColumn>
            {mapFilters(allTags.filter(({category}) => category === "Príležitosti"))}
          </FiltersColumn>
          <FiltersColumn>
            {mapFilters(allTags.filter(({category}) => category === "Ordinárium"))}
          </FiltersColumn>
          <FiltersColumn>
            {mapFilters(allTags.filter(({category}) => category === "Medzispevy"))}
          </FiltersColumn>
        </StyledFilters>
      </Loader>
    </FiltersWrapper>
  )
}

export default Filters