import {Container} from "../../components/styles/Container.styled";
import {ProfileHeader} from "./Profile";
import React, {useContext, useState} from "react";
import {InputWrapper, LoginForm, StyledInput, StyledSubmit} from "./Login";
import UserContext from "../../context/userContext";
import {Link, useNavigate} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {UserApi} from "../../lib/apiHelpers";

function SignUp() {
  const { userData, setUserData } = useContext(UserContext)
  const navigate = useNavigate()
  const {addNotification} = useNotification()

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [repeatedPassword, setRepeatedPassword] = useState<string>("");
  const [captcha, setCaptcha] = useState<string>("");


  const signUp = async (event: any) => {
    event.preventDefault();
    if (parseInt(captcha) !== 42) {
      addNotification("error", "Nevieš rátať??")
      return;
    }
    if (password !== repeatedPassword) {
      addNotification("error", "Heslá sa nezhodujú")
      return;
    }

    if (parseInt(captcha) == 42) {
      event.preventDefault();
      try {
        const response = await UserApi.register({ name, email, password });
        if (response.status === 200) navigate("/login");
        addNotification("success", "Registrácia prebehla úspešne")
      } catch (error: any) {
        addNotification("error", "Nepodarilo sa prihlásiť")
      }
    }
  }


  const logOut = () => {
    setUserData({
      token: undefined,
      name: null,
    });
    localStorage.setItem("token", "");
    navigate("/")
  }


  return (
    <div>
      <Container>
        {
          userData.token ?
            <ProfileHeader>
              <h2>You are currently logged in as {userData.name}</h2>
              <a onClick={logOut}>Log Out</a>
            </ProfileHeader> :
            <LoginForm
              autoComplete="new-password"
              onSubmit={signUp}
            >
              <InputWrapper>
                <StyledInput
                  id={"loginName"}
                  className={"input"}
                  type={"text"}
                  name={"login-name"}
                  placeholder={"Meno"}
                  value={name}
                  onChange={(event) => (setName(event.target.value))}
                  required={true}
                />
              </InputWrapper>
              <InputWrapper>
                <StyledInput
                  id={"loginEmail"}
                  className={"input"}
                  type={"email"}
                  name={"login-name"}
                  placeholder={"Email"}
                  value={email}
                  onChange={(event) => (setEmail(event.target.value))}
                  required={true}
                />
              </InputWrapper>
              <InputWrapper>
                <StyledInput
                  min={8}
                  id={"loginPassword"}
                  className={"input"}
                  type={"password"}
                  name={"login-password"}
                  placeholder={"Heslo"}
                  value={password}
                  onChange={(event) => (setPassword(event.target.value))}
                  required={true}
                />
              </InputWrapper>
              <InputWrapper>
                <StyledInput
                  min={8}
                  id={"loginPassword"}
                  className={"input"}
                  type={"password"}
                  name={"login-password"}
                  placeholder={"Zopakuj heslo"}
                  value={repeatedPassword}
                  onChange={(event) => (setRepeatedPassword(event.target.value))}
                  required={true}
                />
              </InputWrapper>
              <InputWrapper>
                <StyledInput
                  id={"loginCaptcha"}
                  className={"input"}
                  name={"login-captcha"}
                  type={"text"}
                  placeholder={"27 + 15 = "}
                  value={captcha}
                  onChange={(event) => (setCaptcha(event.target.value))}
                  required={true}
                />
              </InputWrapper>
              <StyledSubmit
                id={"loginSubmit"}
                className={"submit"}
                name={"login-submit"}
                type={"submit"}
                value={"Log in"}
              >
                <span className="button-content">Registrovať</span>
              </StyledSubmit>
              <br/>
              <br/>
              <br/>
              <p>Už máš svoj účet? <Link to={"/login"}>Prihlás sa!</Link></p>
            </LoginForm>
        }

      </Container>
    </div>
  )
}

export default SignUp;