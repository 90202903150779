import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {Link, ScrollRestoration} from "react-router-dom";
import styled from "styled-components";// Interfaces
import {SongInSongList} from "../../lib/models";
// Context
import {transitionFast} from "../../components/styles/Mixins";
// Icons
import {SvgIcon} from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FilterContext from "../../context/filterContext";
import Loader from "../../components/Loader";
import {devices} from "../../lib/constants";
import {SongsApi} from "../../lib/apiHelpers";
import NotificationContext from "../../context/notificationContext";
import {useNotification} from "../../hooks/useNotification";

// Styles
export const StyledSongsList = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;

  @media ${devices.tablet} {
    width: 100%;
  }
`
export const SongsListHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  color: ${({theme}) => theme.secondaryColor};
  
  div {
    display: flex;
    gap: 8px;
  }
  a {
    display: flex;
    gap: 8px;
    margin-top: auto;
    margin-bottom: auto;
    color: ${({theme}) => theme.secondaryColor} !important;
    &:hover {
      color: ${({theme}) => theme.blue} !important;
    }
    p {
      margin-top: auto;
      margin-bottom: auto;
    }
    .blue {
      color: ${({theme}) => theme.blue} !important;
    }
    svg {
      margin-top: auto;
      margin-bottom: auto;
    }
    svg:first-child {
      margin-left: -10px;
    }
  }
`
export const SongsListItem = styled(Link) `
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  ${transitionFast}
  
  &:hover {
    color: ${({theme}) => theme.blue} !important;
  }
  p {
    padding: 0;
    margin: 1% 0 1% 0;
  }
`
export const SongsListLetter = styled.div `
  font-weight: 500;
  font-size: 1.4em;
  margin-top: 5%;
  color: ${({theme}) => theme.secondaryColor};
`

/////////////////////////////////////////////////////////////////////////////////
function SongsList(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const {addNotification} = useNotification()

  const letters = ["A", "Á",  "B", "C", "Č", "D", "E", "F", "G", "H", "I", "J", "K", "L", "Ľ", "M", "N", "Ň", "O", "Ó", "P", "Q", "R", "S", "Š", "T", "Ť", "U", "Ú", "V", "W", "X", "Y", "Z", "Ž"]
  const [songsData, setSongsData] = useState<SongInSongList[]>([])

  // String and tags to filter by from user context
  const {searchString, setSearchString} = useContext(FilterContext)
  const {searchTags, setSearchTags} = useContext(FilterContext)
  const {displayFilter, setDisplayFilter} = useContext(FilterContext)
  const {order, setOrder} = useContext(FilterContext)
  const {orderBy, setOrderBy} = useContext(FilterContext)

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await SongsApi.getSongsByFilters(searchString, searchTags, order, orderBy)
      setSongsData(response.data);
    } catch (error: any) {
      console.error(error)
      addNotification("error", "Nastala chyba pri načítaní piesní: " + error.response.data.detail)
    } finally {
      setLoading(false)
    }
  };

  useEffect(()=>{
    fetchData()
  } ,[searchTags, searchString, order, orderBy])

  // scroll to the posistion of the last visited song
  useEffect(() => {
    if (songsData?.length) {
      const scrollPosition = sessionStorage.getItem('scrollPosition');
      if (scrollPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: parseInt(scrollPosition, 10),
            behavior: 'smooth',
          });
        }, 100); // Adjust the delay if needed
        sessionStorage.removeItem('scrollPosition');
      }
    }
  }, [songsData]);

  return (
      <StyledSongsList>
        <SongsListHeader>
          <div>
            <a onClick={() => order === "asc" ? setOrder("desc"): setOrder("asc")}>
              {
                order === "asc" ?
                  <SvgIcon fontSize={"large"} component={ArrowDropDownIcon}/>  :
                  <SvgIcon fontSize={"large"} component={ArrowDropUpIcon}/>
              }
            </a>
            <a onClick={() => setOrderBy("name")}>
              {
                orderBy === "name" ?
                  <p className={"blue"}>Názov</p> :
                  <p>Názov</p>
              }
            </a>
          </div>
          <a onClick={() => displayFilter ? setDisplayFilter(false) : setDisplayFilter(true)}>
            {
              searchTags.length > 0 ?
                  <>
                    <SvgIcon className={"blue"} component={FilterAltIcon}/>
                    <p className={"blue"}>Filter</p>
                  </> :
                  <>
                    <SvgIcon component={FilterAltIcon}/>
                    <p>Filter</p>
                  </>
            }
          </a>
          <div>
            <a onClick={() => order === "asc" ? setOrder("desc"): setOrder("asc")}>
              {
                order === "asc"  ?
                  <SvgIcon fontSize={"large"} component={ArrowDropDownIcon}/> :
                  <SvgIcon fontSize={"large"} component={ArrowDropUpIcon}/>
              }
            </a>
            <a onClick={() => setOrderBy("id")}>
              {
                orderBy === "id" ?
                  <p className={"blue"}>Číslo</p> :
                  <p>Číslo</p>
              }
            </a>
          </div>
        </SongsListHeader>

        <Loader loading={loading}>
          {
            orderBy === "name" ?
              order === "asc" ?
                letters.map((item) => (
                  <div id={item} key={item}>
                    {
                      songsData
                        .filter(({name}) => name.slice(0, 1).toUpperCase() == item)
                        .some(({name}) => name.slice(0, 1).toUpperCase() == item)
                        ? <SongsListLetter >{item}</SongsListLetter>
                        : <></>
                    }
                    {
                      songsData
                        .filter(({name}) => name.slice(0, 1).toUpperCase() == item)
                        .map(({id, name}) => {
                            return (
                              <SongsListItem
                                to={"/song/" + id}
                                key={id}
                                onClick={() =>
                                  sessionStorage.setItem('scrollPosition', window.pageYOffset.toString())
                                }
                              >
                                <p>{name}</p>
                                <p>{id}</p>
                              </SongsListItem>
                            )
                          }
                        )
                    }
                  </div>
                )) :
                letters.reverse().map((item) => (
                  <div id={item} key={item}>
                    {
                      songsData
                        .filter(({name}) => name.slice(0, 1).toUpperCase() == item)
                        .some(({name}) => name.slice(0, 1).toUpperCase() == item)
                        ? <SongsListLetter>{item}</SongsListLetter>
                        : <></>
                    }
                    {
                      songsData
                        .filter(({name}) => name.slice(0, 1).toUpperCase() == item)
                        .map(({id, name}) => {
                            return (
                              <SongsListItem
                                to={"/song/" + id}
                                key={id}
                                onClick={() =>
                                  sessionStorage.setItem('scrollPosition', window.pageYOffset.toString())
                                }
                              >
                                <p>{name}</p>
                                <p>{id}</p>
                              </SongsListItem>
                            )
                          }
                        )
                    }
                  </div>
                ))

              :
              <>
                <SongsListLetter>{songsData[0].id} - {songsData[songsData.length - 1].id}</SongsListLetter>
                {
                  songsData.map(({id, name}) => {
                    return (
                      <SongsListItem
                        to={"/song/" + id}
                        key={id}
                        onClick={() =>
                          sessionStorage.setItem('scrollPosition', window.pageYOffset.toString())
                        }
                      >
                        <p>{name}</p>
                        <p>{id}</p>
                      </SongsListItem>
                    )
                  })
                }
              </>
          }
        </Loader>
      </StyledSongsList>
  )
}

export default SongsList
/*
.filter(({name, id}) => name.toLowerCase().includes(searchString.toLowerCase()))
                .filter(({name}) => name.slice(0, 1).toUpperCase() == item)
                .filter(({name, id, tags}) => ((searchTags.length == 0) || searchTags.every((i:any) => tags.some((item) => i==item.id))))


 */