import React, {useState, useEffect, useContext} from "react";
import {NavLink, useParams, useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components"
// functions
// Icons
import { SvgIcon } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import {transitionFast} from "../../components/styles/Mixins";
import {DeleteForever, Edit, Notes} from "@material-ui/icons";
import UserContext from "../../context/userContext";
import {SongsApi} from "../../lib/apiHelpers";
import {useNotification} from "../../hooks/useNotification";
import {EditorRoleWrapper} from "../../components/roles/EditorRoleWrapper";

// Styles
export const StyledMenu = styled.div `
  width: 100%;
  
  a {
    display: flex;
    text-align: right;
    justify-content: space-between;
    gap: 10px;
    color: ${({theme}) => theme.secondaryColor};
    ${transitionFast};
    &:hover {
      color: ${({theme}) => theme.blue};
    }
    
    p {
      margin-top: auto;
      margin-bottom: auto;
    }
    i {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
`

export interface SongContextMenuProps {
  pdf?: string
  mp3?: string
  text?: string
}

function SongContextMenu({pdf, mp3, text}: SongContextMenuProps): JSX.Element {
  const {addNotification} = useNotification()

  const navigate = useNavigate();
  const {userData} = useContext(UserContext); // Get the user data from the context
  const location = useLocation(); // Use the useLocation hook
  const id = location.pathname.split("/")[2]; // Extract the id from the pathname

  const deleteSong = async () => {
    const confirmDelete = window.confirm('Naozaj chcete vymazať túto pieseň?');

    if (confirmDelete) {
      try {
        const response = await SongsApi.deleteSong(id)

        if (response.status === 200) {
          addNotification("success", "Pieseň úspešne vymazaná.")
          navigate("/");
        } else {
          addNotification("error", "Chyba pri vymazávaní piesne.")
        }
      } catch (e) {
        addNotification("error", "Chyba pri vymazávaní piesne.")
      }
    }
  }

  return (
    <StyledMenu id={"SongContextMenu"}>
      {
        pdf ?
          <a target={"_blank"} href={pdf}>
            <SvgIcon component={PictureAsPdfIcon} fontSize={"medium"}/>
            <p>Download PDF</p>
          </a> :
          <></>
      }
      {
        mp3 ?
          <a target={"_blank"} href={mp3}>
            <SvgIcon component={AudioFileIcon} fontSize={"medium"}/>
            <p>Download MP3</p>
          </a> :
          <></>
      }
      {
        text ?
          <a target={"_blank"} href={text}>
            <SvgIcon component={TextSnippetIcon} fontSize={"medium"}/>
            <p>Export Text</p>
          </a> :
          <></>
      }
      {
        userData.token &&
          <NavLink to={"/editNote/" + id}>
            <SvgIcon component={Notes} fontSize={"medium"}/>
            <p>Upraviť poznámky</p>
          </NavLink>
      }
      {
        userData.token &&
        <EditorRoleWrapper>
          <a href={"/editSong/" + id}>
            <SvgIcon component={Edit} fontSize={"medium"}/>
            <p>Upraviť pieseň</p>
          </a>
        </EditorRoleWrapper>
      }
      {
        userData.token &&
        <EditorRoleWrapper>
          <a onClick={deleteSong}>
            <SvgIcon component={DeleteForever} fontSize={"medium"}/>
            <p>Vymazať pieseň</p>
          </a>
        </EditorRoleWrapper>
      }
    </StyledMenu>
  )
}

export default SongContextMenu