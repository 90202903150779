import React, {useState, useEffect, useContext} from "react";
import {NavLink, useLocation} from "react-router-dom";
import styled from "styled-components";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import css from "*.scss";
import UserContext from "../../context/userContext";
import ThemeContext from "../../context/themeContext";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import Hamburger from 'hamburger-react'
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {SvgIcon} from "@mui/material";
import {darkTheme, lightTheme} from "../../App";
import Logo from "./Logo";
import {transitionFast} from "../styles/Mixins";
import SearchField from "../SearchField";
import SongContextMenu from "../../pages/song/SongContextMenu";
import SongContext from "../../context/songContext";
import PlaylistContextMenu from "../../pages/playlists/PlaylistContextMenu";

// Styles
export const NavigationWrapper = styled.div `
  ${transitionFast};
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: ${({theme}) => theme.backgroundColor};
  color: ${({theme}) => theme.secondaryColor};
`
export const NavigationBorder = styled.div `
  width: 94%;
  border-bottom: 1px solid ${({theme}) => theme.tertiaryColor};
  display: flex;
  justify-content: space-between;
  padding: 0.5% 0 0.5% 0;
`

export const NavigationContent = styled.div `
  width: 20%;
  display: flex;
  justify-content: right;
  gap: 10%;

  a {
    margin-top: auto;
    margin-bottom: auto;
    height: 48px;
    color: ${({theme}) => theme.secondaryColor};
    ${transitionFast};
    &:hover {
      color: ${({theme}) => theme.blue};
    } 
    i {
      margin-top: auto;
      margin-bottom: auto;
    }
    svg {
      margin-top: auto;
      margin-bottom: auto;
      display: block;
      height: 48px;
    }
  }
`

export const StyledMenuIcon = styled.a `
  font-size: 1.2em;
  
  svg {
    font-size: 32px;
  }
`

export const StyledLogoWrapper = styled.div `
  width: 20%;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
`

export const StyledMenu = styled.div<{display: boolean}>  `
  ${transitionFast};
  width: auto;
  margin: 0 3%;
  text-align: right;
  color: ${({theme}) => theme.secondaryColor};
  background-color: ${({theme}) => theme.backgroundColor};
  overflow: hidden;
  height: ${props => props.display ? document.getElementById("menu")?.scrollHeight + "px": "0"};
  border-bottom: ${props => props.display ? "1px solid" : "none"};
  border-color: ${({theme}) => theme.tertiaryColor};
  position: absolute;
  right: 0;
  z-index: 6000;
  
  a {
    padding: 5px;
    display: flex;
    text-align: right;
    justify-content: space-between;
    font-size: 1.2em;
    gap: 10px;
    color: ${({theme}) => theme.secondaryColor};
    ${transitionFast};
    &:hover {
      color: ${({theme}) => theme.blue};
    }
    
    p {
      margin-top: auto;
      margin-bottom: auto;
    }
    i {
      font-size: 32px !important;
      margin-top: auto;
      margin-bottom: auto;
    }
    svg {
      font-size: 32px !important;
    }
  }
`

/*********************** BEGINNING OF THE COMPONENT  *****************************/
function Navigation(): JSX.Element {
  const {isHomepage} = useContext(UserContext);
  const {theme, setTheme} = useContext(ThemeContext)
  const {pdfLink, setPdfLink} = useContext(SongContext)
  const {audioLink, setAudioLink} = useContext(SongContext)
  const {textLink, setTextLink} = useContext(SongContext)
  const {userData, setUserData} = useContext(UserContext)

  const [displayMenu, setDisplayMenu] = useState<boolean>(false)

  const location = useLocation();
  const pathname = location.pathname;

  const handleClickAway = () => {
    setDisplayMenu(false)
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <NavigationWrapper>
            <NavigationBorder>
              <StyledLogoWrapper onClick={() => setDisplayMenu(false)}>
                <Logo />
              </StyledLogoWrapper>
              <NavigationContent>
                { isHomepage.is && <SearchField/> }
                {
                  theme === lightTheme ?
                    <a
                      onClick={() => {
                        setTheme(darkTheme)
                        localStorage.setItem("theme", "darkTheme")
                      }
                    }
                    >
                      <SvgIcon component={DarkModeIcon}/>
                    </a> :
                    <a
                      onClick={() => {
                        setTheme(lightTheme)
                        localStorage.setItem("theme", "lightTheme")
                        }
                      }
                    >
                      <SvgIcon component={LightModeIcon}/>
                    </a>
                }
                <StyledMenuIcon onClick={() => setDisplayMenu(!displayMenu)}>
                  <Hamburger
                    toggled={displayMenu}
                    size={28}
                    rounded
                  />
                </StyledMenuIcon>
              </NavigationContent>
            </NavigationBorder>
          </NavigationWrapper>
          <StyledMenu display={displayMenu} id={"menu"} onClick={() => setDisplayMenu(!displayMenu)}>
            <NavLink to={"/playlists"} end>
              <SvgIcon component={QueueMusicIcon} fontSize={"medium"}/>
              <p>Playlisty</p>
            </NavLink>
            {
              userData.token ?
                <NavLink to={"/profile/"} end>
                  <SvgIcon component={PersonOutlineIcon} fontSize={"medium"}/>
                  <p>Profil</p>
                </NavLink> :
                <NavLink to={"/login"} end>
                  <SvgIcon component={PersonOutlineIcon} fontSize={"medium"}/>
                  <p>Prihlásenie</p>
                </NavLink>
            }
            {
              pdfLink || audioLink || textLink ?
                <SongContextMenu/> :
                null
            }
            {
              pathname.includes("/playlist/") ?
                <PlaylistContextMenu/> :
                null
            }
          </StyledMenu>
        </div>
      </ClickAwayListener>
    </>
  )
}

export default Navigation