import React, {useContext} from "react";
import {useEffect, useState} from "react";
import SongsList from "./SongsList";
import {HomepageProps} from "../../lib/models";
import UserContext from "../../context/userContext";
import {Container} from "../../components/styles/Container.styled"
import Filters from "./Filters";

function Homepage({category}: HomepageProps): JSX.Element {
  const { isHomepage, setIsHomepage } = useContext(UserContext);

  useEffect(() => {
    setIsHomepage({is: true})
    return () => {
      setIsHomepage({is: false})
    }
  },[])

  return (
    <>
      <Filters />
      <Container>
        <SongsList />
      </Container>
    </>
  )
}

export default Homepage