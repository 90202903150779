export class Endpoints {
  private static readonly baseUrl = "https://api.spevnik.jakubcata.eu";

  // USER / ADMIN --------------------------------------------------------
  static get login(): string {
    return `${Endpoints.baseUrl}/api/login`;
  }
  static get register(): string {
    return `${Endpoints.baseUrl}/api/register`;
  }
  static get getCurrentUser(): string {
    return `${Endpoints.baseUrl}/api/userInfo`;
  }

  // TAGS ----------------------------------------------------------------
  static get getTags(): string {
    return `${Endpoints.baseUrl}/api/tags`;
  }

  // SONG -----------------------------------------------------------------
  static get getSongs(): string {
    return `${Endpoints.baseUrl}/api/songList`;
  }
  static getSong(id: string): string {
    return `${Endpoints.baseUrl}/api/song/${id}`;
  }
  static updateSong(id: string): string {
    return `${Endpoints.baseUrl}/api/song/${id}`;
  }
  static createSong(): string {
    return `${Endpoints.baseUrl}/api/song/new`;
  }
  static deleteSong(id: string): string {
    return `${Endpoints.baseUrl}/api/song/${id}`;
  }
  static createNote(id: string): string {
    return `${Endpoints.baseUrl}/api/song/${id}/note`;
  }
  static getNote(id: string): string {
    return `${Endpoints.baseUrl}/api/song/${id}/note`;
  }

  // PLAYLIST -------------------------------------------------------------
  static get getPlaylists(): string {
    return `${Endpoints.baseUrl}/api/playlists`;
  }
  static getPlaylist(id: string): string {
    return `${Endpoints.baseUrl}/api/playlist/${id}`;
  }
  static updatePlaylist(id: string): string {
    return `${Endpoints.baseUrl}/api/playlist/${id}`;
  }
  static createPlaylist(): string {
    return `${Endpoints.baseUrl}/api/playlist/new`;
  }
  static deletePlaylist(id: string): string {
    return `${Endpoints.baseUrl}/api/playlist/${id}`;
  }

  // FILES ----------------------------------------------------------------
  static get uploadFile(): string {
    return `${Endpoints.baseUrl}/api/upload`;
  }
  static downloadFile(id: string): string {
    return `${Endpoints.baseUrl}/api/download/${id}`;
  }
}