import react, {useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import styled from "styled-components";
import {Tag} from "../../../lib/models"
import React from "react";
import { Container } from "../../../components/styles/Container.styled"
import UserContext from "../../../context/userContext"
import {transitionSlow} from "../../../components/styles/Mixins";
import {SongWrapper} from "../../song/Song";
import {
  InputWrapper,
  StyledInput,
  StyledInputSelect,
  StyledInputTextarea,
  StyledSubmit,
  TextareaWrapper
} from "../Login";
import { MultiSelect } from "react-multi-select-component";
import Loader from "../../../components/Loader";
import {FilesApi, SongsApi, TagsApi} from "../../../lib/apiHelpers";
import {useNotification} from "../../../hooks/useNotification";

export const StyledMultiSelect = styled(MultiSelect)`
  outline: none;
  border: none;
  position: relative;
  z-index: 2;
  
  &:hover {
  }
  &:focus {
    outline: none;
    border-radius: 2px;
  }
  &:user-invalid {
    border-color: #FF0000;
  }
  
  .dropdown-container {
    width: 100%;
    background-color: ${({theme})=>theme.backgroundColor};
    //color: ${({theme})=>theme.primaryColor};
    font-size: 1em;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    height: 48px;
    border: 1px solid ${({theme})=>theme.tertiaryColor};
    border-radius: 30px;
    padding: 8px 16px;
    display: flex;
    ${transitionSlow}

    &:hover {
      border: solid 1px ${({theme})=>theme.primaryColor};
    }
    &:focus {
      border: solid 1px ${({theme})=>theme.primaryColor};
    }
    &:user-invalid {
      border-color: #FF0000;
    }
    
    .dropdown-heading {
      height: auto;
      padding: 0;
      margin: auto;
    }
  }
  
`

export const NewSongPartWrapper = styled.div`
  padding: 32px 0 64px 32px;
`

export const SheetsPreview = styled.img`
  width: 100%;  
`

export const StyledInputFileLabel = styled.label`
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1em;
  position: relative;
  overflow: hidden;
  height: 48px;
  padding: 0 2rem;
  border-radius: 1.5rem;
  background: ${({theme})=>theme.backgroundColor};
  background-size: 400%;
  color: ${({theme})=>theme.primaryColor};
  border: 1px solid ${({theme})=>theme.tertiaryColor};
  display: flex;
  justify-content: center;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }

  &:hover::before {
    transform: scaleX(1);
  }

  .button-content {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    background:  ${({theme}) => theme.blue};
    transition: all 0.475s;
  }
`

export const InputFileWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  gap: 16px;
  margin: auto;
  margin-bottom: 16px;
`

function CreateSong():JSX.Element {
  const { addNotification } = useNotification()
  const [loading, setLoading] = useState<boolean>(false)

  const navigate = useNavigate()

  const [name, setName] = useState<string>("")
  const [author, setAuthor] = useState<string>("")
  const [signature, setSignature] = useState<string>("")
  const [allTags, setAllTags] = useState<Tag[]>([])
  const [tags, setTags] = useState<any[]>([])
  const [songParts, setSongParts] = useState<any>([
      {type: "", text: "", image: ""}
  ])

  const [selected, setSelected] = useState([]);
  const options = allTags.map(({ id, name }) => ({
    label: name,
    value: id.toString(),
  }));

  const fetchTags = async () => {
    try {
      setLoading(true)
      const response = await TagsApi.getTags()
      setAllTags(response.data)
    } catch (e) {
      addNotification("error", "Chyba pri načítaní tagov")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTags()
  }, [])

  const handleFormChange = (index: any, event: any, file?: any) => {
    let data = [...songParts];

    data[index][event.target.name] = event.target.value;
    setSongParts(data);
  }
  const handleFileChange = (index: any, event: any) => {
    const updatedSongParts = [...songParts];
    const file = event.target.files[0];

    // Check if a file was selected
    if (file) {
      updatedSongParts[index].image = file;
      setSongParts(updatedSongParts);
    } else {
      // Handle the case where the user clears the file input
      updatedSongParts[index].image = ""; // Clear the sheets property
      setSongParts(updatedSongParts);
    }
  };

  const handleUpload = async (index: any) => {
    if (!songParts[index].image) {
      addNotification("error", "Prosím vyber súbor na nahratie")
      return;
    }

    const formData = new FormData();
    formData.append('file', songParts[index].image);

    try {
      const response = await FilesApi.uploadFile(formData);
      addNotification("success", "Súbor úspešne nahratý")
      const updatedSongParts = [...songParts]; // Create a copy of the array
      updatedSongParts[index] = {
        ...updatedSongParts[index],
        image: response.data.file_id, // Update the image property
      };
      setSongParts(updatedSongParts);
    } catch (error) {
      addNotification("error", "Chyba pri nahrávaní súboru")
    }
  };

  const removeSheets = (index: number) => {
    const updatedSongParts = [...songParts];
    updatedSongParts[index].image = ""; // Clear the sheets property
    setSongParts(updatedSongParts);
  };

  const submit = async (e:any) => {
    e.preventDefault();

    const selectedTags = selected.map((selectedItem:any) => {
      // Find the tag that matches the selected item by value
      const matchedTag = tags.find((tag) => tag.id === selectedItem.value);
      // Return the matched tag if found, or a default object if not found
      return matchedTag || { id: selectedItem.value, name: selectedItem.label };
    });

    try {
      const response = await SongsApi.createSong({
        name: name,
        author: author,
        signature: signature,
        tags: selectedTags,
        songParts: songParts
      })
      addNotification("success", "Pieseň úspešne vytvorená")
      navigate('/profile')
    } catch (e) {
      console.error(e)
      addNotification("error", "Chyba pri vytváraní piesne")
    }
  }

  const addFields = () => {
    let newField = {type: "", text: "", image: ""}
    setSongParts([...songParts, newField])
  }

  const removeFields = (index: any) => {
    let data = [...songParts];
    data.splice(index, 1)
    setSongParts(data)
  }

  return (
      <Container>
        <Loader loading={loading}>
          <SongWrapper>
            <form onSubmit={submit}>
              <InputWrapper>
                <StyledInput
                  name={"name"}
                  type={"text"}
                  placeholder={"Názov piesne"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <StyledInput
                  name={"author"}
                  type={"text"}
                  placeholder={"Autor"}
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <StyledInputSelect
                  required
                  name={"signature"}
                  value={signature}
                  defaultValue={"sharp"}
                  onChange={(event) => setSignature(event.target.value)}
                >
                  <option value="sharp">Krížik</option>
                  <option value="flat">Béčko</option>
                </StyledInputSelect>
              </InputWrapper>
              <StyledMultiSelect
                hasSelectAll={false}
                options={options}
                value={selected}
                onChange={setSelected}
                labelledBy="Tagy"
              />
              <div>
                {}
                {songParts.map((songPart: any, index: number) => {
                  return (
                    <NewSongPartWrapper key={index}>
                      <InputWrapper>
                        <StyledInputSelect
                          required
                          name={"type"}
                          value={songPart.type}
                          defaultValue={"sloha"}
                          onChange={(event) => handleFormChange(index, event)}
                        >
                          <option value="" disabled>
                            Zadaj typ slohy
                          </option>
                          <option value="sloha">Sloha</option>
                          <option value="refren">Refrén</option>
                          <option value="bridge">Bridge</option>
                          <option value="predohra">Predohra</option>
                          <option value="pre-chorus">pre-chorus</option>
                          <option value="zaver">Záver</option>
                        </StyledInputSelect>
                      </InputWrapper>
                      <TextareaWrapper>
                        <StyledInputTextarea
                          required
                          name={"text"}
                          placeholder={"Text slohy, napr. [G]Malé koníky..."}
                          value={songPart.text}
                          onChange={(event) => handleFormChange(index, event)}
                        ></StyledInputTextarea>
                      </TextareaWrapper>
                      <InputFileWrapper>
                        <StyledInputFileLabel
                          htmlFor={"image" + index}
                        >
                          <span className={"button-content"}>Vyhľadaj noty...</span>
                          <StyledInput
                            id={"image" + index}
                            hidden
                            type={"file"}
                            name={"image"}
                            placeholder={"Noty"}
                            accept="image/*"
                            onChange={(event) => {
                              handleFileChange(index, event);
                              event.target.value = ""; // Clear the file input value
                            }}
                          />
                        </StyledInputFileLabel>
                        <StyledSubmit
                          type="button"
                          onClick={() => handleUpload(index)}
                        >
                          <span className={"button-content"}>Nahraj noty</span>
                        </StyledSubmit>
                      </InputFileWrapper>
                      {
                        songParts[index].image ?
                          <SheetsPreview src={"https://api.spevnik.jakubcata.eu/api/download/" + songParts[index].image}/> :
                          null
                      }
                      {
                        songParts[index].image ?
                          <StyledSubmit
                            type="button"
                            onClick={() => removeSheets(index)}
                          >
                            <span className={"button-content"}>Zmaž noty</span>
                          </StyledSubmit> :
                          null
                      }
                      <StyledSubmit
                        type="button"
                        onClick={() => removeFields(index)}
                      >
                        <span className={"button-content"}>Zmaž slohu</span>
                      </StyledSubmit>
                    </NewSongPartWrapper>
                  );
                })}
              </div>
              <div>
                <br />
                <StyledSubmit onClick={addFields} type="button">
                  <span className={"button-content"}>Pridaj slohu</span>
                </StyledSubmit>
              </div>
              <br />
              <StyledSubmit type="submit"> {/* Use type="submit" for the submit button */}
                <span className={"button-content"}>Vytvor pieseň</span>
              </StyledSubmit>
            </form> {/* Close the <form> element */}
          </SongWrapper>
        </Loader>
      </Container>
  )
}

export default CreateSong