import react, {useContext, useEffect, useState} from "react"
import {useParams, useNavigate} from "react-router-dom"
import styled from "styled-components";
import {SongModel, SongInSongList, SongPart, Tag, UserData} from "../../../lib/models"
import axios, { AxiosResponse } from "axios";
import { SvgIcon } from "@mui/material";
import Note from "@mui/icons-material/MusicNote";
import React from "react";
// styled components
import { Container } from "../../../components/styles/Container.styled"
import { StyledCheckboxLabel, StyledCheckboxSpan, StyledCheckbox } from "../../../components/styles/Checkbox.styled";
import SongContext from "../../../context/songContext";
import UserContext from "../../../context/userContext"
import { toggleContextMenuOn } from "../../../functions/toggleContextMenuOn"
import {transitionFast, transitionSlow} from "../../../components/styles/Mixins";
import Popup from "../../../components/Popup";
import { SongsListItem } from "../../homepage/SongsList"
import { PlaylistModel, SongInPlaylist } from "../../../lib/models";
import { PlaylistsApi, SongsApi } from "../../../lib/apiHelpers";
import {SongHeadline, SongWrapper} from "../../song/Song";
import {
    InputWrapper,
    StyledInput,
    StyledInputSelect,
    StyledInputTextarea,
    StyledSubmit,
    TextareaWrapper
} from "../Login";
import { MultiSelect } from "react-multi-select-component";
import FilterContext from "../../../context/filterContext";
import Loader from "../../../components/Loader";
import {useNotification} from "../../../hooks/useNotification";

const StyledMultiSelect = styled(MultiSelect)`
  outline: none;
  border: none;

  &:hover {
  }
  &:focus {
    outline: none;
    border-radius: 2px;
  }
  &:user-invalid {
    background-color: #FF0000;
  }
  
  .dropdown-container {
    width: 100%;
    background-color: ${({theme})=>theme.backgroundColor};
    //color: ${({theme})=>theme.primaryColor};
    font-size: 1em;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    height: 48px;
    border: 1px solid ${({theme})=>theme.tertiaryColor};
    border-radius: 30px;
    padding: 8px 16px;
    display: flex;
    ${transitionSlow}

    &:hover {
      border: solid 1px ${({theme})=>theme.primaryColor};
    }
    &:focus {
      border: solid 1px ${({theme})=>theme.primaryColor};
    }
    &:user-invalid {
      border-color: #FF0000;
    }
    
    .dropdown-heading {
      height: auto;
      padding: 0;
      margin: auto;
    }
  }
  
`

export const NewSongPartWrapper = styled.div`
  padding: 32px 0 64px 32px;
`

function CreatePlaylist():JSX.Element {
    const { addNotification } = useNotification()
    const [loading, setLoading] = useState<boolean>(false)

    const navigate = useNavigate()

    const {userData, setUserData} = useContext(UserContext)
    const [name, setName] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [dirigent, setDirigent] = useState<string>("")
    const [place, setPlace] = useState<string>("")
    const [date, setDate] = useState<string>("")
    const [songs, setSongs] = useState<any>([{title: "", description: "", song_id: ""}])

    const [allSongsData, setAllSongsData] = useState<SongInSongList[]|undefined>(undefined)

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await SongsApi.getSongs().then()
            setAllSongsData(response.data);
        } catch (error) {
            addNotification("error", "Chyba pri načítaní piesní")
        } finally {
            setLoading(false)
        }
    };

    useEffect(()=>{
        fetchData()
    } ,[])


    const handleFormChange = (index: any, event: any) => {
        let data = [...songs];

        data[index][event.target.name] = event.target.value;
        setSongs(data);
    }

    const submit = async (e:any) => {
        e.preventDefault();
        try {
            const response = await PlaylistsApi.createPlaylist({
                name: name,
                description: description || "",
                dirigent: dirigent || "",
                place: place || "",
                datetime: date || "",
                songs: songs,
            }).then(() => {
                addNotification("success", "Playlist bol úspešne vytvorený")
                navigate("/profile")
            })
        } catch (e) {
            addNotification("error", "Chyba pri vytváraní playlistu")
        }
    }

    const addFields = () => {
        let newField = {title: "", description: "", song_id: ""}
        setSongs([...songs, newField])
    }

    const removeFields = (index: any) => {
        let data = [...songs];
        data.splice(index, 1)
        setSongs(data)
    }

    return (
        <Container>
            <form onSubmit={submit}>
                <Loader loading={loading}>
                    <SongWrapper>
                        <InputWrapper>
                            <StyledInput
                              name={"name"}
                              type={"text"}
                              placeholder={"Názov"}
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <StyledInput
                              name={"playlist_description"}
                              type={"text"}
                              placeholder={"Popis playlistu"}
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <StyledInput
                              name={"dirigent"}
                              type={"text"}
                              placeholder={"Dirigent"}
                              value={dirigent}
                              onChange={(e) => setDirigent(e.target.value)}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <StyledInput
                              name={"place"}
                              type={"text"}
                              placeholder={"Miesto"}
                              value={place}
                              onChange={(e) => setPlace(e.target.value)}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <StyledInput
                              name={"date"}
                              type={"date"}
                              placeholder={"Dátum"}
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                            />
                        </InputWrapper>
                        <div>
                            {songs.map((song: any, index: number) => {
                                return (
                                  <NewSongPartWrapper key={index}>
                                      <InputWrapper>
                                          <StyledInputSelect
                                            required
                                            name={"song_id"}
                                            value={song.song_id}
                                            onChange={(event) => handleFormChange(index, event)}
                                          >
                                              <option value="" disabled>
                                                  Zadaj pieseň
                                              </option>
                                              {allSongsData?.map((song) => (
                                                <option key={song.id} value={song.id}>
                                                    {song.name}
                                                </option>
                                              ))}
                                          </StyledInputSelect>
                                      </InputWrapper>
                                      <InputWrapper>
                                          <StyledInput
                                            required
                                            name={"title"}
                                            placeholder={"Titulok piesne, napr. Introitus"}
                                            value={song.title}
                                            defaultValue={""}
                                            onChange={(event) => handleFormChange(index, event)}
                                          />
                                      </InputWrapper>
                                      <TextareaWrapper>
                                          <StyledInputTextarea
                                            name={"description"}
                                            placeholder={"Popis piesne"}
                                            value={song.description}
                                            onChange={(event) => handleFormChange(index, event)}
                                          ></StyledInputTextarea>
                                      </TextareaWrapper>
                                      <StyledSubmit
                                        type="button"
                                        onClick={() => removeFields(index)}
                                      >
                                          <span className={"button-content"}>Zmaž pieseň</span>
                                      </StyledSubmit>
                                  </NewSongPartWrapper>
                                );
                            })}
                        </div>
                        <div>
                            <br />
                            <StyledSubmit type="button" onClick={addFields}>
                                <span className={"button-content"}>Pridaj pieseň</span>
                            </StyledSubmit>
                        </div>
                        <br />
                        <StyledSubmit type="submit">
                            <span className={"button-content"}>Vytvor playlist</span>
                        </StyledSubmit>
                    </SongWrapper>
                </Loader>
            </form>
        </Container>
    )
}

export default CreatePlaylist