import React, {useState, useEffect, useContext} from "react";
import {NavLink, useParams, useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components"
// functions
// Icons
import { SvgIcon } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import {transitionFast} from "../../components/styles/Mixins";
import {DeleteForever, Edit} from "@material-ui/icons";
import UserContext from "../../context/userContext";
import axios from "axios";
import {PlaylistsApi} from "../../lib/apiHelpers";
import {useNotification} from "../../hooks/useNotification";
import {EditorRoleWrapper} from "../../components/roles/EditorRoleWrapper";

// Styles
export const StyledMenu = styled.div `
  width: 100%;
  
  a {
    display: flex;
    text-align: right;
    justify-content: space-between;
    gap: 10px;
    color: ${({theme}) => theme.secondaryColor};
    ${transitionFast};
    &:hover {
      color: ${({theme}) => theme.blue};
    }
    
    p {
      margin-top: auto;
      margin-bottom: auto;
    }
    i {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
`


function PlaylistContextMenu(): JSX.Element {
    const {addNotification} = useNotification()
    const navigate = useNavigate();
    const {userData} = useContext(UserContext); // Get the user data from the context
    const location = useLocation(); // Use the useLocation hook
    const id = location.pathname.split("/")[2]; // Extract the id from the pathname

    const deletePlaylist = async () => {
        const confirmDelete = window.confirm('Naozaj chcete vymazať tento playlist?');

        if (confirmDelete) {
            try {
                const response = await PlaylistsApi.deletePlaylist(id);

                if (response.status === 200) {
                    addNotification("success", "Playlist bol úspešne vymazaný.")
                    navigate("/playlists");
                } else {
                    addNotification("error", "Chyba pri vymazávaní playlistu.")
                }
            } catch (e) {
                addNotification("error", "Chyba pri vymazávaní playlistu.")
            }
        }
    }

    return (
        <StyledMenu id={"SongContextMenu"}>
            {
            userData.token &&
              <EditorRoleWrapper>
                  <a href={"/editPlaylist/" + id}>
                      <SvgIcon component={Edit} fontSize={"medium"}/>
                      <p>Upraviť playlist</p>
                  </a>
              </EditorRoleWrapper>
            }
            {
            userData.token &&
              <EditorRoleWrapper>
                  <a onClick={deletePlaylist}>
                      <SvgIcon component={DeleteForever} fontSize={"medium"}/>
                      <p>Vymazať playlist</p>
                  </a>
              </EditorRoleWrapper>
            }
        </StyledMenu>
    )
}

export default PlaylistContextMenu