import axios, { AxiosResponse } from "axios";
import type {
    Credentials,
    NewPlaylist,
    NewSong,
    PlaylistModel,
    SongInSongList,
    SongModel,
    Tag,
    UserData, UserNew
} from "./models";
import {Endpoints} from "./endpoints";

type RequestIdType = number | string | undefined

const getHeaders = () => {
    return { headers: { "Authorization": "Bearer " + localStorage.getItem("token") }}
}
const getHeadersWithFile = () => {
    return {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            'Content-Type': 'multipart/form-data',
        },
    }
}

// USER API ------------------------------------------------------------------------------------------------------------
export class UserApi {
    static async login(credentials: Credentials): Promise<AxiosResponse<any, any>> {
        return await axios.post<{ token: string, name: string }>(Endpoints.login, credentials);
    }

    static async retrieveCurrentUser(): Promise<AxiosResponse<{ name: string, email: string, role: string }, any>> {
        return await axios.get<{ name: string, email: string, role: string }>(Endpoints.getCurrentUser, getHeaders());
    }

    static async register(newUser: UserNew): Promise<AxiosResponse<any, any>> {
        return await axios.post<{ message: string }>(Endpoints.register, newUser);
    }
}

// SONG API ------------------------------------------------------------------------------------------------------------
export class SongsApi {
    static async getSongs(): Promise<AxiosResponse<SongInSongList[], any>> {
        return await axios.get<SongInSongList[]>(Endpoints.getSongs);
    }
    static async getSong(id: string): Promise<AxiosResponse<SongModel, any>> {
        return await axios.get<SongModel>(Endpoints.getSong(id));
    }
    static async updateSong(id: string, songData: NewSong): Promise<AxiosResponse<SongModel, any>> {
        return await axios.put<SongModel>(Endpoints.updateSong(id), songData, getHeaders())
    }
    static async createSong(songData: NewSong): Promise<AxiosResponse<SongModel, any>> {
        return await axios.post<SongModel>(Endpoints.createSong(), songData, getHeaders())
    }
    static async deleteSong(id: string): Promise<AxiosResponse<SongModel, any>> {
        return await axios.delete<SongModel>(Endpoints.deleteSong(id), getHeaders());
    }

    static async getSongsByFilters(query?: string, tags?: string, order: string = "asc", orderBy: string = "name"): Promise<AxiosResponse<SongInSongList[], any>> {
        let requestUrl = Endpoints.getSongs + "?";
        if (query) {
            requestUrl += `search=${encodeURI(query)}&`
        }
        if (tags) {
            requestUrl += `tags=${encodeURI(tags)}&`;
        }
        requestUrl += "order=" + (["asc", "desc"].includes(order) ? order : "asc");
        requestUrl += "&orderBy=" + (["id", "name", "author"].includes(orderBy) ? orderBy : "name");

        return await axios.get<SongInSongList[]>(requestUrl);
    }

    static async createNoteForSong(id: string, note: any): Promise<AxiosResponse<any, any>> {
        return await axios.post(Endpoints.createNote(id), note, getHeaders());
    }
    static async getNoteForSong(id: string): Promise<AxiosResponse<any, any>> {
        return await axios.get(Endpoints.getNote(id), getHeaders());
    }
}

// PLAYLIST API --------------------------------------------------------------------------------------------------------
export class PlaylistsApi {
    static async getPlaylists(): Promise<AxiosResponse<PlaylistModel[], any>> {
        return await axios.get<PlaylistModel[]>(Endpoints.getPlaylists, getHeaders());
    }
    static async getPlaylist(id: string): Promise<AxiosResponse<PlaylistModel, any>> {
        return await axios.get<PlaylistModel>(Endpoints.getPlaylist(id), getHeaders());
    }
    static async updatePlaylist(id: string, playlistData: NewPlaylist): Promise<AxiosResponse<PlaylistModel, any>> {
        return await axios.put<PlaylistModel>(Endpoints.updatePlaylist(id), playlistData, getHeaders());
    }
    static async createPlaylist(playlistData: NewPlaylist): Promise<AxiosResponse<PlaylistModel, any>> {
        return await axios.post<PlaylistModel>(Endpoints.createPlaylist(), playlistData, getHeaders());
    }
    static async deletePlaylist(id: string): Promise<AxiosResponse<PlaylistModel, any>> {
        return await axios.delete<PlaylistModel>(Endpoints.deletePlaylist(id), getHeaders());
    }
}

// TAG API -------------------------------------------------------------------------------------------------------------
export class TagsApi {
    static async getTags(): Promise<AxiosResponse<Tag[], any>> {
        return await axios.get<Tag[]>(Endpoints.getTags);
    }
}

// FILES API -----------------------------------------------------------------------------------------------------------
export class FilesApi {
    static async uploadFile(formData: FormData): Promise<AxiosResponse<any, any>> {
        return await axios.post(Endpoints.uploadFile, formData, getHeadersWithFile());
    }
    static async downloadFile(id: string): Promise<AxiosResponse<any, any>> {
        return await axios.get(Endpoints.downloadFile(id), getHeaders());
    }
}