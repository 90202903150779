import {Container} from "../../../components/styles/Container.styled";
import {SongWrapper} from "../../song/Song";
import React from "react";
import Loader from "../../../components/Loader";
import {InputWrapper, StyledInput, StyledInputTextarea} from "../Login";
import {useNotification} from "../../../hooks/useNotification";

export const Feedback: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const {addNotification} = useNotification()

  const [feedback, setFeedback] = React.useState<string>("")

  const submitFeedback = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  return (
    <Container>
      <SongWrapper>
        <h2>Pracujeme na tom.</h2>
      </SongWrapper>
    </Container>
  )

  /*
  return (
    <Container>
      <Loader loading={loading}>
        <SongWrapper>
          <form onSubmit={submitFeedback}>
            <InputWrapper>
              <StyledInputTextarea
                placeholder="Napíšte nám svoj feedback"
                required
              ></StyledInputTextarea>
            </InputWrapper>
          </form>
        </SongWrapper>
      </Loader>
    </Container>
  )

   */
}