import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom";
import {Container} from "../../../components/styles/Container.styled";
import UserContext from "../../../context/userContext";
import {PlaylistModel, SongInSongList} from "../../../lib/models";
import axios, {AxiosResponse} from "axios";
import {PlaylistsApi, SongsApi} from "../../../lib/apiHelpers";
import Loader from "../../../components/Loader";
import {SongWrapper} from "../../song/Song";
import {
    InputWrapper,
    StyledInput,
    StyledInputSelect,
    StyledInputTextarea,
    StyledSubmit,
    TextareaWrapper
} from "../Login";
import {NewSongPartWrapper} from "./CreatePlaylist";
import {useNotification} from "../../../hooks/useNotification";

function EditPlaylist():JSX.Element {
    const {addNotification} = useNotification()
    const [loading, setLoading] = useState<boolean>(false)

    const { id } = useParams()
    const navigate = useNavigate()

    const {userData, setUserData} = useContext(UserContext)
    const [name, setName] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [dirigent, setDirigent] = useState<string>("")
    const [place, setPlace] = useState<string>("")
    const [date, setDate] = useState<string>("")
    const [songs, setSongs] = useState<any>([{title: "", description: "", song_id: ""}])

    const [playlistData, setPlaylistData] = useState<PlaylistModel>()
    const [allSongsData, setAllSongsData] = useState<SongInSongList[]|undefined>(undefined)

    const fetchPlaylist = async (id: string) => {
        setLoading(true)
        try {
            const response = await PlaylistsApi.getPlaylist(id)
            setPlaylistData(response.data)
            setName(response.data.name)
            setDescription(response.data.description)
            setDirigent(response.data.dirigent || "")
            setPlace(response.data.place || "")
            const datetimeParts = response.data?.datetime?.split(" ") || ["", ""]; // Split date and time
            const datePart = datetimeParts[0]; // Get the date portion
            setDate(datePart || "");
            setSongs(response.data.songs)
        } catch (e) {
            addNotification("error", "Nepodarilo sa načítať playlist")
        } finally {
            setLoading(false)
        }
    }

    const fetchSongs = async () => {
        setLoading(true)
        try {
            const response = await SongsApi.getSongs()
            setAllSongsData(response.data);
        } catch (error) {
            addNotification("error", "Nepodarilo sa načítať piesne")
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchPlaylist(id || "")
        fetchSongs()
    }, []);


    const submit = async (e:any) => {
        e.preventDefault();
        try {
            const response: AxiosResponse = await PlaylistsApi.updatePlaylist(id || "", {
                name: name,
                description: description || "",
                dirigent: dirigent || "",
                place: place || "",
                datetime: date || "",
                songs: songs,
            })
            addNotification("success", "Playlist bol úspešne upravený")
            navigate("/playlist/" + id)
        } catch (e) {
            addNotification("error", "Nepodarilo sa upraviť playlist")
        }
    }

    const handleFormChange = (index: any, event: any) => {
        let data = [...songs];

        data[index][event.target.name] = event.target.value;
        setSongs(data);
    }

    const addFields = () => {
        let newField = {title: "", description: "", song_id: ""}
        setSongs([...songs, newField])
    }

    const removeFields = (index: any) => {
        let data = [...songs];
        data.splice(index, 1)
        setSongs(data)
    }

    return (
        <Container>
            <form onSubmit={submit}>
                <Loader loading={loading}>
                    <SongWrapper>
                        <InputWrapper>
                            <StyledInput
                              name={"name"}
                              type={"text"}
                              placeholder={"Názov"}
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <StyledInput
                              name={"playlist_description"}
                              type={"text"}
                              placeholder={"Popis playlistu"}
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <StyledInput
                              name={"dirigent"}
                              type={"text"}
                              placeholder={"Dirigent"}
                              value={dirigent}
                              onChange={(e) => setDirigent(e.target.value)}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <StyledInput
                              name={"place"}
                              type={"text"}
                              placeholder={"Miesto"}
                              value={place}
                              onChange={(e) => setPlace(e.target.value)}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <StyledInput
                              name={"date"}
                              type={"date"}
                              placeholder={"Dátum"}
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                            />
                        </InputWrapper>
                        <div>
                            {songs.map((song: any, index: number) => {
                                return (
                                  <NewSongPartWrapper key={index}>
                                      <InputWrapper>
                                          <StyledInputSelect
                                            required
                                            name={"song_id"}
                                            value={song.song_id}
                                            onChange={(event) => handleFormChange(index, event)}
                                          >
                                              <option value="" disabled>
                                                  Zadaj pieseň
                                              </option>
                                              {allSongsData?.map((song) => (
                                                <option key={song.id} value={song.id}>
                                                    {song.name}
                                                </option>
                                              ))}
                                          </StyledInputSelect>
                                      </InputWrapper>
                                      <InputWrapper>
                                          <StyledInput
                                            required
                                            name={"title"}
                                            placeholder={"Titulok piesne, napr. Introitus"}
                                            value={song.title}
                                            defaultValue={""}
                                            onChange={(event) => handleFormChange(index, event)}
                                          />
                                      </InputWrapper>
                                      <TextareaWrapper>
                                          <StyledInputTextarea
                                            name={"description"}
                                            placeholder={"Popis piesne"}
                                            value={song.description}
                                            onChange={(event) => handleFormChange(index, event)}
                                          ></StyledInputTextarea>
                                      </TextareaWrapper>
                                      <StyledSubmit
                                        type="button"
                                        onClick={() => removeFields(index)}
                                      >
                                          <span className={"button-content"}>Zmaž pieseň</span>
                                      </StyledSubmit>
                                  </NewSongPartWrapper>
                                );
                            })}
                        </div>
                        <div>
                            <br />
                            <StyledSubmit type="button" onClick={addFields}>
                                <span className={"button-content"}>Pridaj pieseň</span>
                            </StyledSubmit>
                        </div>
                        <br />
                        <StyledSubmit type="submit">
                            <span className={"button-content"}>Uprav playlist</span>
                        </StyledSubmit>
                    </SongWrapper>
                </Loader>
            </form>
        </Container>
    )
}

export default EditPlaylist