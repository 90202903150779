import React, {useContext} from "react";
import userContext from "../../context/userContext";

export type EditorRoleWrapperProps = {
  children?: React.ReactNode
}

export const EditorRoleWrapper: React.FC<EditorRoleWrapperProps> = ({children}) => {
  const {userData, setUserData} = useContext(userContext)

  return (
    <>
      {
        (userData.role === 'editor' || userData.role === 'admin') && children
      }
    </>
  )
}