import React from "react";
import NotificationContext from "../../context/notificationContext";
import {Notification} from "./Notification";
import styled from "styled-components";

export const NotificationsWrapper = styled.div `
  position: fixed;
  bottom: 1rem;
  right: 3%;
  z-index: 1000;
  top: auto;
  left: auto;
  display: flex;
  flex-direction: column;
  align-items:  flex-end;
`


export const Notifications: React.FC = () => {
  const {notifications} = React.useContext(NotificationContext);

  return (
    <NotificationsWrapper>
      {
        notifications.map((notification: any) => {
          return (
            <Notification

              identifier={notification.identifier}
              type={notification.type}
              timeout={notification.timeout}
              message={notification.message}
            />
          )
        })
      }
    </NotificationsWrapper>
  )
}