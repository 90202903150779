import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom";
import {Container} from "../../../components/styles/Container.styled";
import UserContext from "../../../context/userContext";
import {SongModel, Tag} from "../../../lib/models";
import {SongWrapper} from "../../song/Song";
import {
    InputWrapper,
    StyledInput,
    StyledInputSelect,
    StyledInputTextarea,
    StyledSubmit,
    TextareaWrapper
} from "../Login";
import  {InputFileWrapper, NewSongPartWrapper, SheetsPreview, StyledInputFileLabel, StyledMultiSelect} from "./CreateSong";
import {FilesApi, SongsApi, TagsApi} from "../../../lib/apiHelpers";
import Loader from "../../../components/Loader";
import {useNotification} from "../../../hooks/useNotification";

function EditSong():JSX.Element {
    const { addNotification } = useNotification()
    const [loading, setLoading] = useState<boolean>(false)

    const { id } = useParams()
    const navigate = useNavigate()

    const [songData, setSongData] = useState<SongModel|undefined>()
    const [allTags, setAllTags] = useState<Tag[]>([])

    const [name, setName] = useState<string>("")
    const [author, setAuthor] = useState<string>("")
    const [signature, setSignature] = useState<string>("")
    const [tags, setTags] = useState<any[]>([])
    const [songParts, setSongParts] = useState<any>([
        {type: "", text: "", image: ""}
    ])

    const [selected, setSelected] = useState<any>([]);
    const options = allTags.map(({ id, name }) => ({
        label: name,
        value: id.toString(), // Convert id to string if needed
    }));

    const fetchSong = async (id: string) => {
        try {
            setLoading(true)
            const response = await SongsApi.getSong(id)
            setName(response.data.name)
            setAuthor(response.data.author || "")
            setSignature(response.data.signature)
            setSongParts(response.data.songParts)
            setTags(response.data.tags)
            setSongData(response.data)
        } catch (e: any) {
            addNotification("error", "Nepodarilo sa načítať pieseň")
        } finally {
            setLoading(false)
        }
    }
    const fetchTags = async () => {
        try {
            setLoading(true)
            const response = await TagsApi.getTags()
            setAllTags(response.data)
        } catch (e: any) {
            addNotification("error", "Nepodarilo sa načítať tagy")
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchSong(id || "");
        fetchTags()
    }, []);

    useEffect(() => {
        if (songData && allTags.length) {
            const selectedTags = songData.tags.map((tag) => ({
                label: tag.name,
                value: tag.id.toString(),
            }));
            setSelected(selectedTags);
        }
    }, [songData, allTags]);

    const handleFormChange = (index: any, event: any, file?: any) => {
        let data = [...songParts];

        data[index][event.target.name] = event.target.value;
        setSongParts(data);
    }
    const handleFileChange = (index: any, event: any) => {
        const updatedSongParts = [...songParts];
        const file = event.target.files[0];

        // Check if a file was selected
        if (file) {
            updatedSongParts[index].image = file;
            setSongParts(updatedSongParts);
        } else {
            // Handle the case where the user clears the file input
            updatedSongParts[index].image = ""; // Clear the sheets property
            setSongParts(updatedSongParts);
        }
    };

    const handleUpload = async (index: any) => {
        if (!songParts[index].image) {
            addNotification("error", "Prosím vyber súbor na nahratie")
            return;
        }

        const formData = new FormData();
        formData.append('file', songParts[index].image);

        try {
            const response = await FilesApi.uploadFile(formData);
            addNotification("success", "Súbor bol úspešne nahratý")
            const updatedSongParts = [...songParts]; // Create a copy of the array
            updatedSongParts[index] = {
                ...updatedSongParts[index],
                image: response.data.file_id, // Update the image property
            };
            setSongParts(updatedSongParts);
        } catch (error) {
            addNotification("error", "Nastala chyba pri nahrávaní súboru")
        }
    };

    const removeSheets = (index: number) => {
        const updatedSongParts = [...songParts];
        updatedSongParts[index].image = ""; // Clear the sheets property
        setSongParts(updatedSongParts);
    };

    const addFields = () => {
        let newField = {type: "", text: "", image: ""}
        setSongParts([...songParts, newField])
    }

    const removeFields = (index: any) => {
        let data = [...songParts];
        data.splice(index, 1)
        setSongParts(data)
    }

    const submit = async (e:any) => {
        e.preventDefault();

        const selectedTags = selected.map((selectedItem:any) => {
            // Find the tag that matches the selected item by value
            const matchedTag = tags.find((tag) => tag.id === selectedItem.value);

            // Return the matched tag if found, or a default object if not found
            return matchedTag || { id: selectedItem.value, name: selectedItem.label };
        });

        try {
            const response = await SongsApi.updateSong(id || "", {
                name: name,
                author: author,
                signature: signature,
                songParts: songParts,
                tags: selectedTags
            })
            addNotification("success", "Pieseň bola úspešne upravená")
            navigate("/song/" + id)
        } catch (e) {
            console.error(e)
            addNotification("error", "Nepodarilo sa upraviť pieseň")
        }
    }

    return (
        <Container>
            <Loader loading={loading}>
                <SongWrapper>
                    <form onSubmit={submit}>
                        <InputWrapper>
                            <StyledInput
                              name={"name"}
                              type={"text"}
                              placeholder={"Názov piesne"}
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <StyledInput
                              name={"author"}
                              type={"text"}
                              placeholder={"Autor"}
                              value={author}
                              onChange={(e) => setAuthor(e.target.value)}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <StyledInputSelect
                              required
                              name={"signature"}
                              value={signature}
                              defaultValue={"sharp"}
                              onChange={(event) => setSignature(event.target.value)}
                            >
                                <option value="sharp">Krížik</option>
                                <option value="flat">Béčko</option>
                            </StyledInputSelect>
                        </InputWrapper>
                        <StyledMultiSelect
                          hasSelectAll={false}
                          options={options}
                          value={selected}
                          onChange={setSelected}
                          labelledBy="Tagy"
                        />
                        <div>
                            {}
                            {songParts.map((songPart: any, index: number) => {
                                return (
                                  <NewSongPartWrapper key={index}>
                                      <InputWrapper>
                                          <StyledInputSelect
                                            required
                                            name={"type"}
                                            value={songPart.type}
                                            defaultValue={"sloha"}
                                            onChange={(event) => handleFormChange(index, event)}
                                          >
                                              <option value="" disabled>
                                                  Zadaj typ slohy
                                              </option>
                                              <option value="sloha">Sloha</option>
                                              <option value="refren">Refrén</option>
                                              <option value="bridge">Bridge</option>
                                              <option value="predohra">Predohra</option>
                                              <option value="pre-chorus">Pre-chorus</option>
                                              <option value="zaver">Záver</option>
                                          </StyledInputSelect>
                                      </InputWrapper>
                                      <TextareaWrapper>
                                          <StyledInputTextarea
                                            required
                                            name={"text"}
                                            placeholder={"Text slohy, napr. [G]Malé koníky..."}
                                            value={songPart.text}
                                            onChange={(event) => handleFormChange(index, event)}
                                          ></StyledInputTextarea>
                                      </TextareaWrapper>
                                      <InputFileWrapper>
                                          <StyledInputFileLabel
                                            htmlFor={"image" + index}
                                          >
                                              <span className={"button-content"}>Vyhľadaj noty...</span>
                                              <StyledInput
                                                id={"image" + index}
                                                hidden
                                                type={"file"}
                                                name={"image"}
                                                placeholder={"Noty"}
                                                accept="image/*"
                                                onChange={(event) => {
                                                    handleFileChange(index, event);
                                                    event.target.value = ""; // Clear the file input value
                                                }}
                                              />
                                          </StyledInputFileLabel>
                                          <StyledSubmit
                                            type="button"
                                            onClick={() => handleUpload(index)}
                                          >
                                              <span className={"button-content"}>Nahraj noty</span>
                                          </StyledSubmit>
                                      </InputFileWrapper>
                                      {
                                          songParts[index].image ?
                                            <SheetsPreview src={"https://api.spevnik.jakubcata.eu/api/download/" + songParts[index].image}/> :
                                            null
                                      }
                                      {
                                          songParts[index].image ?
                                            <StyledSubmit
                                              type="button"
                                              onClick={() => removeSheets(index)}
                                            >
                                                <span className={"button-content"}>Zmaž noty</span>
                                            </StyledSubmit> :
                                            null
                                      }
                                      <StyledSubmit
                                        type="button"
                                        onClick={() => removeFields(index)}
                                      >
                                          <span className={"button-content"}>Zmaž slohu</span>
                                      </StyledSubmit>
                                  </NewSongPartWrapper>
                                );
                            })}
                        </div>
                        <div>
                            <br />
                            <StyledSubmit onClick={addFields} type="button">
                                <span className={"button-content"}>Pridaj slohu</span>
                            </StyledSubmit>
                        </div>
                        <br />
                        <StyledSubmit type="submit"> {/* Use type="submit" for the submit button */}
                            <span className={"button-content"}>Uprav pieseň</span>
                        </StyledSubmit>
                    </form> {/* Close the <form> element */}
                </SongWrapper>
            </Loader>
        </Container>
    )
}

export default EditSong