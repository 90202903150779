import NotificationContext from '../context/notificationContext';
import {useContext} from "react";
// @ts-ignore
import { v4 as uuid } from 'uuid'

export const useNotification = () => {
  const {notifications, setNotifications} = useContext(NotificationContext);

  const addNotification = (type: string = "success", message: string = "Hello there... General KENOBII!", timeout: number = 5000) => {
    const identifier: string = uuid();
    setNotifications((prevNotifications: []) => [...prevNotifications, { type, message, timeout, identifier }]);
  };

  const removeNotification = (identifier: string) => {
    setNotifications((prevNotifications: []) =>
      prevNotifications.filter((notification: any) => notification.identifier !== identifier)
    );
  };

  return {addNotification, removeNotification};

}