import React, {useContext, useEffect, useState} from "react"
import {Link, useNavigate} from "react-router-dom"
import styled from "styled-components";
import {Container} from "../../components/styles/Container.styled";
import userContext from "../../context/userContext";
import AddIcon from "@mui/icons-material/Add";
import {SvgIcon} from "@mui/material";
import {transitionFast} from "../../components/styles/Mixins";
import {UserApi} from "../../lib/apiHelpers";
import {AxiosResponse} from "axios";
import {useNotification} from "../../hooks/useNotification";
import Loading from "react-loading";
import Loader from "../../components/Loader";
import {EditorRoleWrapper} from "../../components/roles/EditorRoleWrapper";
import {Logout} from "@mui/icons-material";
import {MailOutline, Send} from "@material-ui/icons";

export const ProfileWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: auto;
`

export const ProfileHeader = styled.div `
  text-align: left;
  margin: 0 0 5% 0;
  
  h1, h2 {
    margin: 0;
    padding: 0;
  }
  p {
    margin-top: 0;
    padding-top: 0;
  }
`

export const ProfileOptions = styled.div `
  text-align: left;

  a {
    padding: 5px;
    display: flex;
    text-align: left;
    justify-content: left;
    gap: 10px;
    color: ${({theme}) => theme.primaryColor};
    ${transitionFast};
    &:hover {
      color: ${({theme}) => theme.blue};
    }
    &:hover svg, &:hover i {
      color: ${({theme}) => theme.blue};
    }

    p, svg, i, span {
      margin-top: auto;
      margin-bottom: auto;
    }
    svg, i {
      color: ${({theme}) => theme.secondaryColor};
      ${transitionFast};
    }
  }
`
export const ProfileOptionsGroup = styled.div `
  margin-bottom: 2rem;
`

function Profile():JSX.Element {
  const {userData, setUserData} = useContext(userContext)
  const {addNotification} = useNotification()
  const [loading, setLoading] = useState<boolean>(false)

  const navigate = useNavigate()

  const getCurrentUser = async () => {
    let token = localStorage.getItem("token");
    if (token === null) {
      localStorage.setItem("token", "");
      token = "";
    }
    try {
      setLoading(true)
      const response = await UserApi.retrieveCurrentUser()
      if (response.status !== 200) {
        addNotification("error", "Nie si prihlásený.")
        navigate("/login")
        return
      }
      if (response.data) {
        setUserData({
          token: token,
          name: response.data.name,
          email: response.data.email,
          role: response.data.role,
        })
      }
      console.log(response.data)
    } catch (e: any) {
      addNotification("error", "Nie si prihlásený.")
      navigate("/login")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCurrentUser()
  }, []);

  const logOut = () => {
    setUserData({
      token: undefined,
      name: null,
    });
    localStorage.setItem("token", "");
    navigate("/")
  }

  return (
    <Container>
      <Loader loading={loading}>
        <ProfileWrapper>
          <ProfileHeader>
            <h2>{userData.name}</h2>
            <p>{userData.email}</p>
          </ProfileHeader>
          <ProfileOptions>
            <ProfileOptionsGroup>
              <a onClick={logOut}>
                <SvgIcon component={Logout} fontSize={"medium"}/>
                <span>Odhlásiť sa</span>
              </a>
            </ProfileOptionsGroup>
            <ProfileOptionsGroup>
              <Link to={"/feedback"}>
                <SvgIcon component={MailOutline} fontSize={"medium"}/>
                <span>Napíš nám</span>
              </Link>
            </ProfileOptionsGroup>
            <ProfileOptionsGroup>
              <EditorRoleWrapper>
                <Link to={"/newSong/"}>
                  <SvgIcon component={AddIcon} fontSize={"medium"}/>
                  <p>Vytvoriť Pieseň</p>
                </Link>
                <Link to={"/newPlaylist/"}>
                  <SvgIcon component={AddIcon} fontSize={"medium"}/>
                  <p>Vytvoriť Playlist</p>
                </Link>
              </EditorRoleWrapper>
            </ProfileOptionsGroup>
          </ProfileOptions>
        </ProfileWrapper>
      </Loader>
    </Container>
  )
}

export default Profile