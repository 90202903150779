import { Navigate } from "react-router-dom";
import React, { useEffect } from "react";
import { UserApi } from "../../lib/apiHelpers";
import { AxiosResponse } from "axios";
import UserContext from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import {useNotification} from "../../hooks/useNotification";

interface ProtectedRouteProps {
  element: JSX.Element;
  roles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, roles }) => {
  const { userData, setUserData } = React.useContext(UserContext);
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = React.useState<boolean | null>(null);
  const {addNotification} = useNotification()

  const getCurrentUser = async () => {
    let token = localStorage.getItem("token");
    if (!token) {
      token = "";
    }
    try {
      const response: AxiosResponse = await UserApi.retrieveCurrentUser();
      if (roles.includes(response.data.role)) {
        // User is authorized
        setUserData(
          {
            token: token,
            name: response.data.name,
            email: response.data.email,
            role: response.data.role,
          }
        );
        setIsAuthorized(true);
      } else {
        // User is not authorized
        setIsAuthorized(false);
        addNotification("error", "Nemáš dostatočné práva na zobrazenie tejto stránky.")
        navigate("/login");
      }
    } catch (e) {
      console.error(e);
      setIsAuthorized(false);
      navigate("/login"); // Redirect on error (e.g., token is invalid)
      addNotification("error", "Nemáš dostatočné práva na zobrazenie tejto stránky.")
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  if (isAuthorized === null) {
    // Optionally, you could return a loading spinner here while checking the user's role
    return <Loader></Loader>; // or a loading component
  }

  return isAuthorized ? element : null;
};

export default ProtectedRoute;