import React from "react";
import {useEffect, useState, useContext} from "react";
import {Link, useNavigate} from "react-router-dom"
import UserContext from "../../context/userContext";
import Axios from "axios";
import styled from "styled-components"
import {transitionFast, transitionSlow} from "../../components/styles/Mixins";
import {Container} from "../../components/styles/Container.styled"
import {ProfileHeader} from "./Profile";
import {UserApi} from "../../lib/apiHelpers";
import {useNotification} from "../../hooks/useNotification";

export const StyledInput = styled.input `
  width: 100%;
  background-color: ${({theme})=>theme.backgroundColor};
  color: ${({theme})=>theme.primaryColor};
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  height: 100%;
  border: 1px solid ${({theme})=>theme.tertiaryColor};
  border-radius: 30px;
  padding: 8px 16px;
  ${transitionSlow}

  &:hover {
  }
  &:focus {
    outline: none;
    border-radius: 2px;
  }
  &:user-invalid {
    border-color: #FF0000;
  }
`

export const StyledInputSelect = styled.select `
  width: 100%;
  background-color: ${({theme})=>theme.backgroundColor};
  color: ${({theme})=>theme.primaryColor};
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  height: 100%;
  border: 1px solid ${({theme})=>theme.tertiaryColor};
  border-radius: 30px;
  padding: 8px 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  ${transitionSlow}

  &:hover {
  }
  &:focus {
    outline: none;
    border-radius: 2px;
  }
  &:user-invalid {
    border-color: #FF0000;
  }
`

export const StyledInputTextarea = styled.textarea `
  width: 100%;
  background-color: ${({theme})=>theme.backgroundColor};
  color: ${({theme})=>theme.primaryColor};
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  height: 100%;
  border: 1px solid ${({theme})=>theme.tertiaryColor};
  border-radius: 30px;
  padding: 8px 16px;
  ${transitionSlow}

  &:hover {
  }
  &:focus {
    outline: none;
    border-radius: 2px;
  }
  &:user-invalid {
    border-color: #FF0000;
  }
`

export const LoginForm = styled.form `
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding-top: 10%;
  
  p {
    text-align: center;
  }
`
export const InputWrapper = styled.div `
  --timing: 0.3s;
  --border-height: 2px;
  --input-bg: #fff;
  --border-color: ${({theme})=>theme.blue};
  --border-radius: 30px;
  --after-border-radius: 1px;
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  //padding-inline: 0.8em;
  border-radius: 30px;
  transition: border-radius 0.5s ease;
  background: var(--input-bg,#fff);
  margin: auto;
  margin-bottom: 16px;

  &:before {
    content: "";
    position: absolute;
    background: var(--border-color);
    transform: scaleX(0);
    transform-origin: center;
    width: 100%;
    height: var(--border-height);
    left: 0;
    bottom: 0;
    border-radius: 1px;
    transition: transform var(--timing) ease;
  }
  /* Hover on Input */
  &:focus-within {
    border-radius: var(--after-border-radius);
  }
  &:focus-within:before {
    transform: scale(1);
  }
`

export const TextareaWrapper = styled.div `
  --timing: 0.3s;
  --border-height: 2px;
  --input-bg: #fff;
  --border-color: ${({theme})=>theme.blue};
  --border-radius: 30px;
  --after-border-radius: 1px;
  position: relative;
  width: 100%;
  height: 128px;
  display: flex;
  align-items: center;
  //padding-inline: 0.8em;
  border-radius: 30px;
  transition: border-radius 0.5s ease;
  background: var(--input-bg,#fff);
  margin: auto;
  margin-bottom: 16px;

  &:before {
    content: "";
    position: absolute;
    background: var(--border-color);
    transform: scaleX(0);
    transform-origin: center;
    width: 100%;
    height: var(--border-height);
    left: 0;
    bottom: 0;
    border-radius: 1px;
    transition: transform var(--timing) ease;
  }
  /* Hover on Input */
  &:focus-within {
    border-radius: var(--after-border-radius);
  }
  &:focus-within:before {
    transform: scale(1);
  }
`

export const StyledSubmit = styled.button `
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1em;
  position: relative;
  overflow: hidden;
  height: 48px;
  padding: 0 2rem;
  border-radius: 1.5rem;
  background: ${({theme})=>theme.backgroundColor};
  background-size: 400%;
  color: ${({theme})=>theme.primaryColor};
  border: 1px solid ${({theme})=>theme.tertiaryColor};

  &:hover {
    cursor: pointer;
  }
  
  &:hover::before {
    transform: scaleX(1);
  }
  
  .button-content {
    position: relative;
    z-index: 1;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    background:  ${({theme}) => theme.blue};
    transition: all 0.475s;
  }
`
export const LoginHeader = styled.div `
  text-align: center;
  margin: 0 0 5% 0;
  color: ${({theme}) => theme.secondaryColor};
`


function Login(): JSX.Element {
  const {addNotification} = useNotification()
  const [name, setName] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [captcha, setCaptcha] = useState<string>("")
  const [error, setError] = useState<any>();

  const { userData, setUserData } = useContext(UserContext);

  const navigate = useNavigate()

  const login = async (event: any) => {
    event.preventDefault();
    if (parseInt(captcha) !== 42) {
      addNotification("error", "Nevieš rátať??")
      return;
    }
    if (parseInt(captcha) == 42) {
      event.preventDefault();
      try {
        const response = await UserApi.login({ name, password });
        if (response.data) {
          localStorage.setItem("token", response.data.token || "");

          const userDataResponse = await UserApi.retrieveCurrentUser();

          if (userDataResponse.data) {
            setUserData({
              token: response.data.token,
              name: userDataResponse.data.name,
              email: userDataResponse.data.email,
              role: userDataResponse.data.role,
            });
          }

          navigate("/profile");
          addNotification("success", "Prihlásenie prebehlo úspešne")
        }
      } catch (error: any) {
        addNotification("error", "Nepodarilo sa prihlásiť")
      }
    }
  }

  /*
  const submit = async (e:any) => {
    if (parseInt(captcha) == 42) {
      e.preventDefault();
      try {
        const loginRes = await Axios.post(
          "https://api.spevnik.jakubcata.eu/api/login",
          {name, password}
        );
        if (loginRes.data) {
          navigate("/profile")
        }
        setUserData({
          token: loginRes.data.token,
          name: loginRes.data.name,
        });
        localStorage.setItem("token", loginRes.data.token);
      } catch (e:any) {
        e.response.data.detail && setError(e.response.data.detail);
      }
    }
  };
  */

  const logOut = () => {
    setUserData({
      token: undefined,
      name: null,
    });
    localStorage.setItem("token", "");
    navigate("/")
  }

  return (
    <Container>
      {
        userData.token ?
          <LoginHeader>
            <h2>You are currently logged in as {userData.name}</h2>
            <a onClick={logOut}>Log Out</a>
          </LoginHeader> :
          <LoginForm
            autoComplete="new-password"
            onSubmit={login}
          >
            <InputWrapper>
              <StyledInput
                id={"loginName"}
                className={"input"}
                type={"text"}
                name={"login-name"}
                placeholder={"Meno"}
                value={name}
                onChange={(event) => (setName(event.target.value))}
                required={true}
              />
            </InputWrapper>
            <InputWrapper>
              <StyledInput
                id={"loginPassword"}
                className={"input"}
                type={"password"}
                name={"login-password"}
                placeholder={"Heslo"}
                value={password}
                onChange={(event) => (setPassword(event.target.value))}
                required={true}
              />
            </InputWrapper>
            <InputWrapper>
              <StyledInput
                id={"loginCaptcha"}
                className={"input"}
                name={"login-captcha"}
                type={"text"}
                placeholder={"27 + 15 = "}
                value={captcha}
                onChange={(event) => (setCaptcha(event.target.value))}
                required={true}
              />
            </InputWrapper>
            <StyledSubmit
              id={"loginSubmit"}
              className={"submit"}
              name={"login-submit"}
              type={"submit"}
              value={"Log in"}
            >
              <span className="button-content">Prihlásiť</span>
            </StyledSubmit>
            <br/>
            <br/>
            <br/>
            <p>Ešte nemáš svoj účet? <Link to={"/signup"}>Registruj sa!</Link></p>
          </LoginForm>
      }
    </Container>
  )
}

export default Login