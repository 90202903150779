import react, {useContext, useEffect, useState} from "react"
import {useParams, useNavigate, useSearchParams} from "react-router-dom"
import styled from "styled-components";
import { SvgIcon } from "@mui/material";
import React from "react";
import { Container } from "../../components/styles/Container.styled"
import { StyledCheckboxLabel, StyledCheckboxSpan, StyledCheckbox } from "../../components/styles/Checkbox.styled";
import SongContext from "../../context/songContext";
import UserContext from "../../context/userContext"
import { transitionFast } from "../../components/styles/Mixins";
import Loader from "../../components/Loader";
import {ArrowDownward, ArrowUpward, Refresh} from "@material-ui/icons";
import {PlaylistModel, SongModel} from "../../lib/models";
import {SongsApi} from "../../lib/apiHelpers";
import {useNotification} from "../../hooks/useNotification";

export const SongWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: auto;
  min-height: 100vh;
  color: ${({ theme }) => theme.secondaryColor};
`
export const SongHeadline = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.primaryColor};
  margin-bottom: 32px;
  a {
    ${transitionFast};
  }
  a:hover {
    color: ${({ theme }) => theme.blue};
  }
  h1 {
    margin-bottom: 0;
  }
  h2 {
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
    color: ${({ theme }) => theme.secondaryColor};
  }
`
export const SongPartsWrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.primaryColor};
  
  img {
    width: 100%;
    height: auto;
  }
`
export const SheetsWrapper = styled.div`
  width: 100vw;
  transform: translateX(calc((100vw - 800px)/(-2)));
  padding: 0 0;
  margin-bottom: 5%;
  background-color: #FFFFFF;
  display: flex;
  
  @media (max-width: 800px) {
    transform: translateX(-5%);
  }
  
  img {
    max-width: 800px;
    width: 100%;
    margin: auto;
    height: auto;
  }
`
export const SongPart = styled.div`
  margin-bottom: 10%;
`

export const Chord = styled.div<{ display: boolean }>`
  display: ${props => props.display ? "block" : "none"};
  font-size: 0.9em;
  font-weight: bold;
  color: ${({ theme }) => theme.blue};
  p {
    margin: 0;
    padding: 0;
  }
`

export const Toolbar = styled.div`
  display: inline-block;
  float: right;

  div{
    display: flex; 
    justifyContent: flex-end
  }
`

export const SongMenu = styled.div<{ display: boolean }>  `
  ${transitionFast};
  color: ${({ theme }) => theme.secondaryColor};
  background-color: ${({ theme }) => theme.backgroundColor};
  overflow-y: hidden;
  height: ${props => props.display ? (document.getElementById("songMenu")?.scrollHeight || 0) + 5 + "px" : "0"};
  border-bottom: ${props => props.display ? "1px solid" : "none"};
  border-color: ${({ theme }) => theme.tertiaryColor};
  position: absolute;
  right: 30%;
  z-index: 6000;
  float: right;
  margin-left:auto;
  margin-right:0;
  
  a {
    padding: 5px;
    display: flex;
    text-align: right;
    justify-content: right;
    gap: 10px;
    color: ${({ theme }) => theme.primaryColor};
    ${transitionFast};
    &:hover {
      color: ${({ theme }) => theme.blue};
    }
    
    p {
      margin-top: auto;
      margin-bottom: auto;
    }
    i {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
`

export const SongNotes = styled.div`
  margin-top: 5%;
  margin-bottom: 5%;
  h3 {
    margin-bottom: 0;
    color: ${({ theme }) => theme.primaryColor};
  }
  p {
    margin-top: 0;
  }  
`

export const TransposeWrapper = styled.div `
  display: flex;
  justify-content: left;
  gap: 16px;
  margin-top: 5%;
  margin-bottom: 5%;
  
  * {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  svg {
    ${transitionFast}
    &:hover {
      color: ${({theme}) => theme.blue};
      cursor: pointer;
    }
  }
`

function Song(input: any): JSX.Element {
  const params = useParams()
  //const [searchParams, setSearchParams] = useSearchParams();
  const id = input?.id || params?.id

  const [loading, setLoading] = useState<boolean>(false)
  const {addNotification} = useNotification()

  const { userData } = useContext(UserContext)

  const { displayChords, setDisplayChords } = useContext(SongContext)
  const { displaySheets, setDisplaySheets } = useContext(SongContext)
  const { pdfLink, setPdfLink } = useContext(SongContext)
  const { audioLink, setAudioLink } = useContext(SongContext)
  const { textLink, setTextLink } = useContext(SongContext)

  const [songData, setSongData] = useState<SongModel|undefined>()
  const [notes, setNotes] = useState<string | null>(null)

  //const initialTransposes = searchParams.get('transposes') || "0";
  const [numberOfTransposes, setNumberOfTransposes] = useState<number>(0)

  const fetchNotes = async (id: string) => {
    try {
      setLoading(true);
      const response = await SongsApi.getNoteForSong(id);
      setNotes(response.data.note);
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchSong = async (id: string) => {
    try {
      setLoading(true);
      const response = await SongsApi.getSong(id);
      setSongData(response.data);

      // TODO FILES UPLOADING
      setPdfLink("a");
      setAudioLink("a");
      setTextLink("a");

    } catch (error: any) {
      addNotification("error", "Nepodarilo sa načítať pieseň: " + error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  /*
  useEffect(() => {
    if (numberOfTransposes) {
      setSearchParams({transposes: numberOfTransposes.toString()})
    } else if (searchParams.has("transposes") && numberOfTransposes === 0) {
      setSearchParams({transposes: numberOfTransposes.toString()})
    }
  }, [numberOfTransposes]);
  */

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    fetchSong(id)
    fetchNotes(id)
      //.then(
      //() => {
       // if (songData?.signature === "sharp") {
         // transposeUpInitial(numberOfTransposes)
        //} else {
          //transposeUpFlatInitial(numberOfTransposes)
        //}

     // }
    //);

    return () => {
      setPdfLink("");
      setAudioLink("");
      setTextLink("");
    };
  }, [id]);

  const transposeUp = (amount: number) => {
    const scale = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"]
    const normalizeMap = { "Cb": "B", "Db": "C#", "Eb": "D#", "Fb": "E", "Gb": "F#", "Ab": "G#", "Bb": "A#", "E#": "F", "B#": "C" }

    const chords = document.getElementsByClassName("chord")

    for (let i = 0; i < chords.length; i++) {
      chords[i].innerHTML = chords[i].innerHTML.replace(/[CDEFGAB](b|#)?/g, (match) => {
        // @ts-ignore
        let newChordIndex = (scale.indexOf((normalizeMap[match] ? normalizeMap[match] : match)) + amount) % scale.length;
        return scale[newChordIndex < 0 ? newChordIndex + scale.length : newChordIndex]
      })
    }
    setNumberOfTransposes(numberOfTransposes + amount);
  }

  const transposeUpFlat = (amount: number) => {
    const scale = ["C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab", "A", "Bb", "B"]
    const normalizeMap = { "B#": "C", "C#": "Db", "Cb": "B", "E#": "F", "Fb": "E", "F#": "Gb", "G#": "Ab", "A#": "Bb", "D#": "Eb" }

    const chords = document.getElementsByClassName("chord")

    for (let i = 0; i < chords.length; i++) {
      chords[i].innerHTML = chords[i].innerHTML.replace(/[CDEFGAB](b|#)?/g, (match) => {
        // @ts-ignore
        let newChordIndex = (scale.indexOf((normalizeMap[match] ? normalizeMap[match] : match)) + amount) % scale.length;
        return scale[newChordIndex < 0 ? newChordIndex + scale.length : newChordIndex]
      })
    }
    setNumberOfTransposes(numberOfTransposes + amount);
  }

  const transposeUpInitial = (amount: number) => {
    const scale = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"]
    const normalizeMap = { "Cb": "B", "Db": "C#", "Eb": "D#", "Fb": "E", "Gb": "F#", "Ab": "G#", "Bb": "A#", "E#": "F", "B#": "C" }

    const chords = document.getElementsByClassName("chord")

    for (let i = 0; i < chords.length; i++) {
      chords[i].innerHTML = chords[i].innerHTML.replace(/[CDEFGAB](b|#)?/g, (match) => {
        // @ts-ignore
        let newChordIndex = (scale.indexOf((normalizeMap[match] ? normalizeMap[match] : match)) + amount) % scale.length;
        return scale[newChordIndex < 0 ? newChordIndex + scale.length : newChordIndex]
      })
    }
  }

  const transposeUpFlatInitial = (amount: number) => {
    const scale = ["C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab", "A", "Bb", "B"]
    const normalizeMap = { "B#": "C", "C#": "Db", "Cb": "B", "E#": "F", "Fb": "E", "F#": "Gb", "G#": "Ab", "A#": "Bb", "D#": "Eb" }

    const chords = document.getElementsByClassName("chord")

    for (let i = 0; i < chords.length; i++) {
      chords[i].innerHTML = chords[i].innerHTML.replace(/[CDEFGAB](b|#)?/g, (match) => {
        // @ts-ignore
        let newChordIndex = (scale.indexOf((normalizeMap[match] ? normalizeMap[match] : match)) + amount) % scale.length;
        return scale[newChordIndex < 0 ? newChordIndex + scale.length : newChordIndex]
      })
    }
  }

  return (
    <Container>
      <SongWrapper>
        <Loader loading={loading}>
          <SongHeadline>
            <div style={{ display: "inline-block" }}><h1>{songData?.name}</h1></div>
            <p>
              {songData?.tags.map(({ name, id }, i) => (
                <span className={"song__tags-span"} key={id}>
                    {name}
                  {i < songData?.tags.length - 1 && ", "}
                  </span>
              ))}
            </p>
          </SongHeadline>
          {
            (notes) &&
            <SongNotes>
              <h3>Poznámky:</h3>
              <p>{notes}</p>
            </SongNotes>
          }
          {
            songData &&
              <>
                <StyledCheckboxLabel>
                  <StyledCheckbox
                    type={"checkbox"}
                    id={"display-chords-checkbox"}
                    checked={displayChords}
                    onChange={event => setDisplayChords(!displayChords)}
                  />
                  <StyledCheckboxSpan />
                  Zobraziť akordy
                </StyledCheckboxLabel>
                <StyledCheckboxLabel>
                  <StyledCheckbox
                    type={"checkbox"}
                    id={"display-sheets-checkbox"}
                    checked={displaySheets}
                    onChange={event => setDisplaySheets(!displaySheets)}
                  />
                  <StyledCheckboxSpan />
                  Zobraziť noty
                </StyledCheckboxLabel>
              </>
          }
          {
            displayChords && songData ?
              <TransposeWrapper>
                <p>Transpozícia: </p>
                {
                  songData?.signature === "sharp" ?
                    <>
                      <SvgIcon onClick={() => { displayChords && transposeUp(1) }} component={ArrowUpward} fontSize={"medium"}/>
                      <SvgIcon onClick={() => { displayChords && transposeUp(-1) }} component={ArrowDownward} fontSize={"medium"}/>
                      <SvgIcon onClick={() => { displayChords && transposeUp(12 - numberOfTransposes % 12) }} component={Refresh} fontSize={"medium"}/>
                    </> :
                    <>
                      <SvgIcon onClick={() => { displayChords && transposeUpFlat(1) }} component={ArrowUpward} fontSize={"medium"}/>
                      <SvgIcon onClick={() => { displayChords && transposeUpFlat(-1) }} component={ArrowDownward} fontSize={"medium"}/>
                      <SvgIcon onClick={() => { displayChords && transposeUpFlat(12 - numberOfTransposes % 12) }} component={Refresh} fontSize={"medium"}/>
                    </>
                }
              </TransposeWrapper> :
              null
          }
          <SongPartsWrapper>
            <>
              {
                songData?.songParts?.map(({ order, type, label, image, containsChords, lines, text }) => (
                  <SongPart key={order}>
                    {
                      image && displaySheets &&
                        <SheetsWrapper>
                          <img alt={"sheets"} src={"https://api.spevnik.jakubcata.eu/api/download/" + image} />
                        </SheetsWrapper>
                    }
                    {
                      console.log(label)
                    }
                    <h3>{label}: </h3>
                    {
                      containsChords ?
                        lines?.map((line) => (
                          <div className={"song__line"}>
                            {
                              line.tokens.map(({ chord, text }) => (
                                <div className={"song__line-token"}>
                                  <Chord display={displayChords} className={"chord"}>
                                    {
                                      //displayChords ?
                                      chord ?
                                        <p>{chord}&nbsp;</p> :
                                        <p>&nbsp;</p>
                                      //<></>
                                    }
                                  </Chord>
                                  <div className={"text"}>
                                    {
                                      (chord.length > text.length) && !(/\s$/.test(text)) && (text.length > 1) && displayChords ?
                                        <span><p>{text}</p><p>&nbsp;-&nbsp;&nbsp;</p></span> :
                                        <p>{text.replace(/ /g, "\u00A0")}</p>
                                    }
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        ))
                        :
                        lines?.map((line) => (
                          <div className={"song__line"}>
                            {
                              line.tokens.map(({ text }) => (
                                <div className={"song__line-token"}>
                                  <div className={"text"}>
                                    <p>{text.replace(/ /g, "\u00A0")}</p>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        ))
                    }
                  </SongPart>
                ))
              }
            </>
          </SongPartsWrapper>
        </Loader>
      </SongWrapper >
    </Container>
  )
}

export default Song