import React, {useRef, useState, useEffect} from "react";
import axios, {AxiosResponse} from "axios";
import {Route, Routes} from "react-router";
import {ThemeProvider} from "styled-components";
// Global Context
import UserContext from "./context/userContext";
import FilterContext from "./context/filterContext";
import ThemeContext from "./context/themeContext";
import SongContext from "./context/songContext";
import NotificationContext from "./context/notificationContext";
// Functions
import {toggleSidePanelOff} from "./functions/toggleSidePanel";
// Components
import Homepage from "./pages/homepage/Homepage";
import Navigation from "./components/navigation/Navigation";
import Login from "./pages/user/Login";
import Song from "./pages/song/Song";
// styles
import {Overlay} from "./components/styles/Overlay.styled";
import {toggleContextMenuOff} from "./functions/toggleContextMenuOn";
import PlaylistsList from "./pages/playlists/PlaylistsList";
import Playlist from "./pages/playlists/Playlist";
import PlaylistSong from "./pages/playlists/PlaylistSong";
import Profile from "./pages/user/Profile";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {userInfo} from "os";
import type {UserData} from "./lib/models";
import CreateSong from "./pages/user/admin/CreateSong";
import EditSong from "./pages/user/admin/EditSong";
import CreatePlaylist from "./pages/user/admin/CreatePlaylist";
import EditPlaylist from "./pages/user/admin/EditPlaylist";
import {Endpoints} from "./lib/endpoints";
import {UserApi} from "./lib/apiHelpers";
import {Notifications} from "./components/notifications/Notifications";
import {ScrollButton} from "./components/ScrollButton";
import SignUp from "./pages/user/SignUp";
import ProtectedRoute from "./components/roles/ProtectedRoute";
import {Feedback} from "./pages/user/admin/Feedback";
import {EditNote} from "./pages/user/admin/EditNote";

// Definition of light and dark theme
export const lightTheme = {
  name: "light",
  backgroundColor: "#FFFFFF",
  primaryColor: "#1E1E1E",
  secondaryColor: "#7A7A7A",
  tertiaryColor: "#DDDDDD",
  blue: "#05DCF0",
  red: "#d92626",
  green: "#00FF00",
}
export const darkTheme = {
  name: "dark",
  backgroundColor: "#1E1E1E",
  primaryColor: "#FFFFFF",
  secondaryColor: "#999999",
  tertiaryColor: "#383838",
  blue: "#05C8DC",
  red: "#e05252",
  green: "#00FF00",
}

////////////////////////////////////////////////////////////////////////////////////
function App() {
  // ALL THE CONTEXT
  const [userData, setUserData] = useState<UserData>({
    token: undefined,
    name: undefined,
    email: undefined,
    role: undefined,
  });
  const [isHomepage, setIsHomepage] = useState({
    is: false
  });
  const [searchString, setSearchString] = useState<string>("")
  const [searchTags, setSearchTags] = useState<number[]>([])
  const [displayFilter, setDisplayFilter] = useState<boolean>(false)
  const [theme, setTheme] = useState<object>(localStorage.getItem("theme") === "darkTheme" ? darkTheme : lightTheme)
  const [displayChords, setDisplayChords] = useState<boolean>(true)
  const [displaySheets, setDisplaySheets] = useState<boolean>(true)
  const [pdfLink, setPdfLink] = useState<string>("")
  const [audioLink, setAudioLink] = useState<string>("")
  const [textLink, setTextLink] = useState<string>("")
  const [order, setOrder] = useState<string>("asc")
  const [orderBy, setOrderBy] = useState<string>("name")
  const [notifications, setNotifications] = useState<any>([])


  const getCurrentUser = async () => {
    let token = localStorage.getItem("token");
    if (token === null) {
      localStorage.setItem("token", "");
      token = "";
    }
    try {
      await UserApi.retrieveCurrentUser()
        .then((response: AxiosResponse) => {
          setUserData({
            token: token,
            name: response.data.name,
            email: response.data.email,
            role: response.data.role,
          });
        });
    } catch (e) {
      console.error(e);
    }
  }

  // Version with global userData straight here, without user ID
  /*
  const getUserInfo = async () => {

    try {
      await axios
        .get<UserData>(endpointUrls.userInfo, {
          headers: { "Authorization": "Bearer " + token },
        })
        .then((response: AxiosResponse) => {
          setUserData({
            token: token,
            name: response.data.name,
          })
        })
    } catch (e) {
      console.error(e)
    }
  }
  */

  const isMounted = useRef(false);
  const getSetting = () => {
    // Retrieve the values from localStorage
    const storedDisplayChords = localStorage.getItem("displayChords");
    const storedDisplaySheets = localStorage.getItem("displaySheets");

    // Update the state with the retrieved values or use default values if they don't exist
    if (storedDisplayChords === "true" || storedDisplayChords === null) {
      setDisplayChords(true);
    } else {
      setDisplayChords(false);
    }

    if (storedDisplaySheets === "true" || storedDisplaySheets === null) {
      setDisplaySheets(true);
    } else {
      setDisplaySheets(false);
    }
  };
  const updateSettings = () => {
    if (isMounted.current) {
      // Convert boolean values to strings and store them in localStorage
      localStorage.setItem('displayChords', displayChords.toString());
      localStorage.setItem('displaySheets', displaySheets.toString());
    }
  };

  // Add this effect to trigger the updateSettings function whenever displayChords or displaySheets changes
  useEffect(() => {
    if (isMounted.current) {
      updateSettings();
    } else {
      // Set the component as mounted after the initial render
      isMounted.current = true;
    }
  }, [displayChords, displaySheets]);

  useEffect(() => {
    getCurrentUser()
    getSetting()
  }, []);

  return (
    <div>
      <NotificationContext.Provider value={{notifications, setNotifications}}>
        <UserContext.Provider value={{ userData, setUserData, isHomepage, setIsHomepage, }}>
          <FilterContext.Provider
            value={{searchString, setSearchString, searchTags, setSearchTags, displayFilter, setDisplayFilter, order, setOrderBy, orderBy, setOrder}}>
            <SongContext.Provider value={{displayChords, setDisplayChords, displaySheets, setDisplaySheets, pdfLink, setPdfLink, audioLink, setAudioLink, textLink, setTextLink}}>
              <ThemeContext.Provider value={{theme, setTheme}}>
                <ThemeProvider theme={theme}>
                  <Navigation/>
                  <ScrollButton/>
                  <Notifications/>
                  <Routes>
                    <Route
                      path={"/"}
                      element={<Homepage />}
                    />
                    <Route
                      path={"/login"}
                      element={<Login />}
                    />
                    <Route
                      path={"/signup"}
                      element={<SignUp />}
                    />
                    <Route
                      path={"/profile"}
                      element={<Profile />}
                    />
                    <Route
                      path={"/playlists"}
                      element={<PlaylistsList />}
                    />
                    <Route
                      path={"/playlist/:id"}
                      element={<Playlist />}
                    />
                    <Route
                      path={"/playlistSong/:songId/:playlistId"}
                      element={<PlaylistSong />}
                    />
                    <Route
                      path={"/song/:id"}
                      element={<Song />}
                    />
                    <Route
                      path={"/newSong/"}
                      element={
                        <ProtectedRoute element={<CreateSong/>} roles={["admin", "editor"]}/>
                      }
                    />
                    <Route
                        path={"/editSong/:id"}
                        element={
                      <ProtectedRoute element={<EditSong/>} roles={["admin", "editor"]} />
                    }
                    />
                    <Route
                      path={"/newPlaylist/"}
                      element={
                        <ProtectedRoute element={<CreatePlaylist/>} roles={["admin", "editor"]} />
                        }
                    />
                    <Route
                      path={"/editPlaylist/:id"}
                      element={
                        <ProtectedRoute element={<EditPlaylist/>} roles={["admin", "editor"]} />
                    }
                    />
                    <Route
                      path={"/editNote/:id"}
                      element={
                        <ProtectedRoute element={<EditNote/>} roles={["admin", "editor", "basic"]} />
                      }
                    />
                    <Route
                      path={"/feedback"}
                      element={
                        <ProtectedRoute element={<Feedback/>} roles={["admin", "editor", "basic"]} />
                      }
                    />
                  </Routes>
                </ThemeProvider>
              </ThemeContext.Provider>
            </SongContext.Provider>
          </FilterContext.Provider>
        </UserContext.Provider>
      </NotificationContext.Provider>
    </div>
  );
}

export default App;
