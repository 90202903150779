import styled from 'styled-components'
import {transitionFast} from "./Mixins";

export const StyledCheckboxLabel = styled.label  `
  --button-width: 36px; //3.5em
  --button-height: 20px; //2em
  --toggle-diameter: 16px;
  --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
  --toggle-shadow-offset: 6px;
  --toggle-wider: 32px;
  --color-grey: #7a7a7a;
  --color-green: ${({theme}) => theme.blue};
  
  display: flex;
  gap: 1em;
  margin: 5px 0;
  ${transitionFast}
    
  &:hover {
    cursor: pointer;
    color: ${({theme}) => theme.blue};
  }
`

export const StyledCheckbox = styled.input `
  display: none;
  
  &:checked + span {
    background-color: var(--color-green) ;
  }
  &:checked + span::after {
    transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset))) ;
    box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1) ;
  }
  
  &:active + span::after {
    width: var(--toggle-wider) ;
  }
  &:checked:active + span::after {
    transform: translateX(calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset))) ;
  }
`

export const StyledCheckboxSpan = styled.span `
  display: inline-block;
  width: var(--button-width);
  height: var(--button-height);
  background-color: #7a7a7a;
  border-radius: calc(var(--button-height) / 2);
  position: relative;
  transition: 0.3s all ease-in-out;
  
  &::after {
    content: "";
    display: inline-block;
    width: var(--toggle-diameter);
    height: var(--toggle-diameter);
    background-color: #fff;
    border-radius: calc(var(--toggle-diameter) / 2);
    position: absolute;
    top: var(--button-toggle-offset);
    transform: translateX(var(--button-toggle-offset));
    box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
    transition: 0.3s all ease-in-out;
  }
`


