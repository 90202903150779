import React from "react";
import {useEffect, useState} from "react";
import {PlaylistModel} from "../../lib/models";
import {Container} from "../../components/styles/Container.styled"
import {SongsListItem, SongsListLetter, StyledSongsList} from "../homepage/SongsList";
import styled from "styled-components";
import {transitionFast} from "../../components/styles/Mixins";
import {Link} from "react-router-dom";
import {PlaylistsApi} from "../../lib/apiHelpers";
import {useNotification} from "../../hooks/useNotification";
import Loader from "../../components/Loader";

export const PlaylistListItem = styled(Link)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  ${transitionFast}

  &:hover {
    color: ${({theme}) => theme.blue} !important;
  }
  p {
    padding: 0;
    margin: 1% 0 1% 0;
  }
  span {
    color: ${({theme}) => theme.secondaryColor} !important;
  }
`

function PlaylistsList(): JSX.Element {
  const {addNotification} = useNotification()
  const [loading, setLoading] = useState<boolean>(false)

  const [playlistsData, setPlaylistsData] = useState<PlaylistModel[]>([])

  const fetchPlaylists = async () => {
    try {
      setLoading(true)
      const response = await PlaylistsApi.getPlaylists()
      setPlaylistsData(response.data)
    } catch (error: any) {
      addNotification("error", "Chyba pri načítaní playlistov: " + error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(()=>{
    fetchPlaylists()
  } ,[])

  return (
    <>
      <Container>
        <StyledSongsList>
          <Loader loading={loading}>
            {/* Display "Nadchádzajúce" header if there are upcoming playlists */}
            {playlistsData?.filter(({ datetime }) => {
              const parsedDate = new Date(datetime || "");
              const currentDate = new Date();
              currentDate.setHours(0, 0, 0, 0)
              return parsedDate >= currentDate; // Only upcoming playlists
            })
              .length > 0 && (
              <SongsListLetter>
                Nadchádzajúce
              </SongsListLetter>
            )}
            {/* Filter and map upcoming playlists */}
            {playlistsData
              ?.filter(({ datetime }) => {
                const parsedDate = new Date(datetime || "");
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0)
                return parsedDate >= currentDate; // Only upcoming playlists
              })
              .map(({ name, id, datetime }) => {
                const parsedDate = new Date(datetime || "");
                const formattedDate = `${parsedDate.getDate().toString().padStart(2, '0')}.${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}.${parsedDate.getFullYear()}`;

                return (
                  <PlaylistListItem to={"/playlist/" + id} key={id}>
                    <p>
                      {name} <br />
                      <span>{formattedDate}</span>
                    </p>
                    <p>{id}</p>
                  </PlaylistListItem>
                );
              }).reverse()}
            {/* Display "Minulé" header if there are already happened playlists */}
            {playlistsData
              ?.filter(({ datetime }) => {
                const parsedDate = new Date(datetime || "");
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0)
                return parsedDate < currentDate; // Only already happened playlists
              })
              .length > 0 && (
              <SongsListLetter>
                Minulé
              </SongsListLetter>
            )}
            {/* Filter and map already happened playlists */}
            {playlistsData
              ?.filter(({ datetime }) => {
                const parsedDate = new Date(datetime || "");
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0)
                return parsedDate < currentDate; // Only already happened playlists
              })
              .map(({ name, id, datetime }) => {
                const parsedDate = new Date(datetime || "");
                const formattedDate = `${parsedDate.getDate().toString().padStart(2, '0')}.${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}.${parsedDate.getFullYear()}`;

                return (
                  <PlaylistListItem to={"/playlist/" + id} key={id}>
                    <p>
                      {name} <br />
                      <span>{formattedDate}</span>
                    </p>
                    <p>{id}</p>
                  </PlaylistListItem>
                );
              }).reverse()
            }
          </Loader>
        </StyledSongsList>
      </Container>
    </>
  )
}

export default PlaylistsList