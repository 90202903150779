import React, {useContext} from "react";
import styled from "styled-components";
import ReactLoading from "react-loading";

export const StyledLoader = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

export interface LoaderProps {
  loading?: boolean;
  children?: React.ReactNode;
}

const Loader:React.FC<LoaderProps> = ({loading= true, children}) => {
  return (
    <>
      {
        loading ?
          <StyledLoader>
            <ReactLoading type={'spinningBubbles'} color={'#05DCF0'} height={42} width={42} />
          </StyledLoader> :
          <>{children}</>
      }
    </>
  )
}

export default Loader