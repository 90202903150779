import React, { useContext } from "react";
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { PlaylistModel } from "../../lib/models";
import { PlaylistsApi } from "../../lib/apiHelpers";
import { navigationUrlParts, playlistPropertyTranslations } from "../../lib/constants";
import { Container } from "../../components/styles/Container.styled"
import { SongsListItem, StyledSongsList } from "../homepage/SongsList";
import { SongHeadline, Toolbar } from "../song/Song";
import Loader from "../../components/Loader";
import styled from "styled-components";
import {PlaylistListItem} from "./PlaylistsList";
import {formatDate} from "../../lib/helperFunctions";
import {useNotification} from "../../hooks/useNotification";

export const PlaylistInfo = styled.div`
  display: flex;
  justify-content: left;
  text-align: left;
  
  p:first-child {
    width: 150px;
}
`

function Playlist(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const { addNotification } = useNotification()

  const { id } = useParams()
  const [playlistData, setPlaylistData] = useState<PlaylistModel>()

  const fetchPlaylist = async () => {
    try {
      setLoading(true)
      const response = await PlaylistsApi.getPlaylist(id || "")
      setPlaylistData(response.data)
    } catch (error: any) {
      addNotification("error", "Chyba pri načítaní playlistu: ")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    fetchPlaylist()
  }, [])


  return (
    <>
      <Container>
        <Loader loading={loading}>
          <StyledSongsList>
            <SongHeadline>
              <h1>{playlistData?.name}</h1>
              {playlistData?.datetime ? <PlaylistInfo><p>Dátum:</p> <p>{formatDate(playlistData.datetime)}</p></PlaylistInfo> : null}
              {playlistData?.place ? <PlaylistInfo><p>Miesto:</p> <p>{playlistData.place}</p></PlaylistInfo> : null}
              {playlistData?.dirigent ? <PlaylistInfo><p>Dirigent:</p> <p>{playlistData.dirigent}</p></PlaylistInfo> : null}
              <br/>
              {playlistData?.description ? <PlaylistInfo><p>Popis:</p> <p>{playlistData.description}</p></PlaylistInfo> : null}
            </SongHeadline>
            { playlistData && <div style={{ display: "inline-block" }}><h2>{"Piesne"}</h2></div> }
            {
              playlistData?.songs?.map(({ title, song_id , song_name, description}) => {
                return (
                  <PlaylistListItem to={navigationUrlParts.playlistSong + `/${song_id}/${id}`} key={song_id}>
                    <p>
                      {song_name} <br />
                      <span>{title}</span> <br />
                      <span>{description}</span>
                    </p>
                    <p>{song_id}</p>
                  </PlaylistListItem>
                )
              })
            }
          </StyledSongsList>
        </Loader>

      </Container>
    </>
  )
}

export default Playlist