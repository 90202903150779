import styled from 'styled-components'
import {transitionFast} from "./Mixins";

export const Container = styled.div`
  color: ${({theme}) => theme.primaryColor};
  background-color: ${({theme}) => theme.backgroundColor};
  padding: 2% 5% 5% 5%;
  min-height: 100vh;
  ${transitionFast}
  
  a {
    color: ${({theme}) => theme.primaryColor};
    text-decoration: none;
    ${transitionFast};

    &:hover {
      color: ${({theme}) => theme.blue};
      cursor: pointer;
    }
  }
`
export const BaseContainer = styled.div`
  color: ${({theme}) => theme.primaryColor};
  background-color: ${({theme}) => theme.backgroundColor};
  padding: 2% 5% 0 5%;
  ${transitionFast}
  
  a {
    color: ${({theme}) => theme.primaryColor};
    text-decoration: none;
    ${transitionFast};

    &:hover {
      color: ${({theme}) => theme.blue};
      cursor: pointer;
    }
  }
`

