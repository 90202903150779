import React, { useContext } from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom"
import { useEffect, useState } from "react";
import { PlaylistModel } from "../../lib/models";
import { PlaylistsApi } from "../../lib/apiHelpers";
import {  } from "../../lib/constants";

// styled components
import {Container, BaseContainer} from "../../components/styles/Container.styled"
import songsList, { SongsListItem, StyledSongsList } from "../homepage/SongsList";
import Song, { SongHeadline, Toolbar, SongWrapper } from "../song/Song";
import { AxiosResponse } from "axios";
import {StyledSubmit} from "../user/Login";
import Loader from "../../components/Loader";
import styled from "styled-components";
import {ArrowBack, ArrowForward, ArrowLeft, ArrowRight, ArrowUpward} from "@material-ui/icons";
import {SvgIcon} from "@mui/material";
import {transitionFast} from "../../components/styles/Mixins";
import {useNotification} from "../../hooks/useNotification";

export const PlaylistContainer = styled.div `
  width: 100%;
  max-width: 800px;
  margin: auto;
  color: ${({ theme }) => theme.secondaryColor};
  border-bottom: 1px solid ${({ theme }) => theme.tertiaryColor};
`

export const PlaylistNavWrapper = styled.div `
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  
  * {
    margin-top: 0;
  }
  
  svg {
    ${transitionFast}
    &:hover {
      color: ${({ theme }) => theme.blue};
      cursor: pointer;
    }
  }
`

export interface PlaylistSongProps {
  playlistId: string
}

function PlaylistSong(): JSX.Element {
  const { addNotification } = useNotification()
  const [loading, setLoading] = useState<boolean>(false)

  const songId = useParams()?.songId || ""
  const playlistId = useParams()?.playlistId || ""

  const [songIdState, setSongIdState] = useState<string>(songId)
  const [playlistData, setPlaylistData] = useState<PlaylistModel>()

  const fetchPlaylist = async () => {
    try {
      setLoading(true)
      const response = await PlaylistsApi.getPlaylist(playlistId)
      setPlaylistData(response.data)
    } catch (error: any) {
      addNotification("error", "Chyba pri načítaní playlistu: ")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setSongIdState(songId)
    fetchPlaylist()
  }, [songId, playlistId])

  const currentIndex = playlistData?.songs?.findIndex((song) => song.song_id.toString() === songId) || 0;

  // Calculate the index of the next and previous songs
  const nextIndex = (currentIndex + 1) % (playlistData?.songs?.length || 1);
  const prevIndex = (currentIndex - 1 + (playlistData?.songs?.length || 1) ) % (playlistData?.songs?.length || 1);

  // Get the next and previous songs' data
  const nextSong = playlistData?.songs?.[nextIndex];
  const prevSong = playlistData?.songs?.[prevIndex];

    // Inside your component
    const navigate = useNavigate();

    // Function to navigate to a URL
    const navigateToUrl = (url: string) => {
       navigate(url)
    };

  return (
    <>
      <BaseContainer>
        <Loader loading={loading}>
          <PlaylistContainer>
            {
              playlistData && playlistData?.songs?.length > 1 ?
                <PlaylistNavWrapper>
                  { prevSong && <SvgIcon onClick={() => navigateToUrl(`/playlistSong/${prevSong.song_id}/${playlistId}`)} component={ArrowBack} fontSize={"large"}/> }
                  <SongHeadline>
                    <h2>{playlistData?.songs[currentIndex].title}</h2>
                    <Link to={"/playlist/" + playlistData.id}>{playlistData.name}</Link>
                  </SongHeadline>
                  { nextSong && <SvgIcon onClick={() => navigateToUrl(`/playlistSong/${nextSong.song_id}/${playlistId}`)} component={ArrowForward} fontSize={"large"}/> }
                </PlaylistNavWrapper> :
                <PlaylistNavWrapper>
                  <SongHeadline>
                    <h2>{playlistData?.songs[currentIndex].title}</h2>
                    <Link to={"/playlist/" + playlistData?.id}>{playlistData?.name}</Link>
                  </SongHeadline>
                </PlaylistNavWrapper>
            }
            <p>
              {playlistData?.songs[currentIndex].description}
            </p>
          </PlaylistContainer>
        </Loader>
      </BaseContainer>
      <Song id={songIdState} />
    </>
  )
}

export default PlaylistSong